import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import MDTypography from "components/MDTypography";
import { getNotifications } from "services/profileapis";
import { deleteNotification } from "services/Notifications";
import {
  Divider,
  IconButton,
  useTheme as T,
  useMediaQuery,
  Menu,
  MenuItem,
} from "@mui/material";
import { formatDateTime } from "utils/formatDateTime";
import { MoreVertRounded } from "@mui/icons-material";
import { useMaterialUIController } from "context";
import colors from "assets/theme/base/colors";
import MDBox from "components/MDBox";
import { readAllNotifications } from "services/Notifications";
import showToast from "utils/toastUtils";

function Notifications() {
  const [loading, setLoading] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const t = T();
  const { black, white } = colors;
  const isSmall = useMediaQuery(t.breakpoints.down("sm"));

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchNotifications = async () => {
    setLoading(true);
    try {
      const response = await getNotifications();
      if (response) {
        const filteredNotifications = response.map((item) => ({
          notificationId: item.id,
          message: `${item.description}.`,
          date: formatDateTime(item.created_at),
          isRead: item.is_read,
          market: item.exchange,
        }));

        setNotificationData(filteredNotifications);
      }
    } catch (error) {
      console.log("could not fetch notifications", error);
    } finally {
      setLoading(false);
    }
  };

  const removeNotification = async (id) => {
    try {
      const response = await deleteNotification(id);
      if (response) {
        showToast(`${response.message}`, "success");
        handleClose();
        fetchNotifications();
      }
    } catch (error) {
      console.error("could not remove notification", error);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  useEffect(()=>{
    if(notificationData){
      readAllNotifications()
    }
  },[])

  return (
    <Grid xs={12} sm={12} md={12} lg={12} xl={12} height={"100%"}>
      {loading ? (
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="success" />
        </Grid>
      ) : notificationData.length <= 0 ? (
        <Grid
          height={"100%"}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MDTypography>No Updates!</MDTypography>
        </Grid>
      ) : (
        <Grid>
          {notificationData.map((item) => (
            <>
              <Grid
                id={item.notificationId}
                key={item.notificationId}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <MDTypography
                    sx={{
                      fontSize: isSmall ? "13px" : "16px",
                      Width: isSmall && "200px",
                    }}
                  >
                    {item.message}
                  </MDTypography>
                  <MDTypography
                    sx={{
                      fontSize: isSmall ? "12px" : "13px",
                      fontWeight: 500,
                    }}
                  >
                    {item.market}
                  </MDTypography>
                </Grid>
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    gap: "10px",
                  }}
                >
                  <MDTypography
                    sx={{
                      fontSize: isSmall ? "12px" : "13px",
                      paddingTop: "5px",
                    }}
                    data-testid="notification-date"
                  >
                    {item.date}
                  </MDTypography>
                  <MDBox>
                    <IconButton
                      aria-label={item.notificationId}
                      id="long-button"
                      aria-controls={open ? "long-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={handleClick}
                      data-testid="menu-button"
                    >
                      <MoreVertRounded
                        style={{
                          cursor: "pointer",
                          color: darkMode ? white.main : black.main,
                          height: "20px",
                          width: "20px",
                        }}
                      />
                    </IconButton>
                    <Menu
                      role="delete-menu"
                      id="long-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      sx={{
                        "& .MuiPaper-root": {
                          width: "100px",
                        },
                      }}
                    >
                      <MenuItem
                       data-testid="delete-notification"
                        onClick={() => {
                          removeNotification(item.notificationId);
                        }}
                      >
                        Delete
                      </MenuItem>
                    </Menu>
                  </MDBox>
                </Grid>
              </Grid>
              <Divider variant="fullWidth" />
            </>
          ))}
        </Grid>
      )}
    </Grid>
  );
}
export default Notifications;
