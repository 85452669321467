import React, { useState, useRef, useEffect } from "react";
import MDBox from "components/MDBox";

import {
  Grid,
  Divider,
  Card,
  IconButton,
  useMediaQuery,
  useTheme as T,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Addwatchlist,
  getInstruments,
  removeFromWatchlist,
} from "../../../services/portifolioapis";
import { Search } from "@mui/icons-material";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import BookmarkBorderRoundedIcon from "@mui/icons-material/BookmarkBorderRounded";
import BookmarkAddedRoundedIcon from "@mui/icons-material/BookmarkAddedRounded";
import { bookMarkStatus } from "services/transactionApis";
import showToast from "utils/toastUtils";
import { formatAndMultiplyValueAssetItem } from "utils/formatAndMultiplyValue";

const SearchBar = ({ market, exchange, page, pageSize, fetchData1 }) => {
  const navigate = useNavigate();
  const t = T();
  const isLarge = useMediaQuery(t.breakpoints.down("xl"));
  const isSmall = useMediaQuery(t.breakpoints.down("md"));
  const [showCard, setShowCard] = useState(false);
  const [assetData, setAssetData] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedChipIndex, setSelectedChipIndex] = useState(0);
  const [selectedLabel, setSelectedLabel] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);
  const cardRef = useRef(null);

  const updateDataFromSessionStorage = () => {
    const enabled = sessionStorage.getItem("enabledmarkets");
    const selectedExchange = sessionStorage.getItem("selectedExchange");

    if (enabled) {
      const markets = JSON.parse(enabled);
      const formattedOptions = markets.map((market) => ({
        label: market,
        selected: false,
      }));

      setOptions(formattedOptions);

      if (selectedExchange) {
        const selectedIndex = formattedOptions.findIndex(
          (option) => option.label === selectedExchange
        );
        if (selectedIndex >= 0) {
          setSelectedChipIndex(selectedIndex);
          setSelectedLabel(selectedExchange);
        } else if (formattedOptions.length > 0) {
          setSelectedChipIndex(0);
          setSelectedLabel(formattedOptions[0].label);
        }
      } else if (formattedOptions.length > 0) {
        setSelectedChipIndex(0);
        setSelectedLabel(formattedOptions[0].label);
      }
    }
  };

  useEffect(() => {
    updateDataFromSessionStorage();
  }, []);

  useEffect(() => {
    if (market !== null && selectedLabel !== "") {
      fetchData(selectedLabel, page, pageSize, searchQuery);
    }
  }, [market, page, pageSize, searchQuery, selectedLabel]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target) &&
        cardRef.current &&
        !cardRef.current.contains(event.target)
      ) {
        setShowCard(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputRef]);

  const [status, setStatus] = useState(false);
  useEffect(() => {
    if (status && selectedLabel) {
      fetchData(selectedLabel, page, pageSize, searchQuery);
      setStatus(false);
    }
  }, [status, searchQuery, selectedLabel]);

  const handleBookmark = async (assetStaus, instrumentId, instrumentType) => {
    try {
      const data = {
        instrumentId: parseInt(instrumentId),
        exchange: instrumentType,
      };
      if (assetStaus) {
        await removeFromWatchlist(data);
        showToast("Removed from watchlist", "success");
        setStatus(true);
      } else {
        await Addwatchlist(data);
        // fetchData(selectedLabel, page, pageSize, searchQuery);
        showToast("Added to watchlist", "success");
        setStatus(true);
      }
      fetchData1();
    } catch (error) {
      console.error("Error updating watchlist:", error);
    }
  };

  // const fetchData = async (selectedLabel, page, pageSize, searchQuery) => {

  //   try {
  //     const pageValues = {
  //       exchange: selectedLabel,
  //       page: page,
  //       pageSize: pageSize,
  //       searchPattern: searchQuery,
  //     };
  //     const zTokens = await getInstruments(pageValues);
  //     const nseDataArray = await Promise.all(
  //       zTokens?.instruments?.map(async (item) => {
  //         const status = await bookMarkStatus(
  //           item.FinancialInstrumentID,
  //           item.Exchange
  //         );
  //         return {
  //           instrumentid: item.FinancialInstrumentID,
  //           name2: item.Name,
  //           value: item.LastPrice,
  //           instrumentType: item.Exchange,
  //           TradingSymbol: item.TradingSymbol,
  //           added: status,
  //         };
  //       })
  //     );

  //     setAssetData(nseDataArray);
  //     // console.log("nse checking", nseDataArray);
  //     if (zTokens.instruments.length > 0) {
  //       setSelectedLabel(zTokens.instruments[0].Exchange);
  //     }
  //   } catch (error) {
  //     console.error("Error in API calls:", error);
  //   }
  // };

  const fetchData = async (selectedLabel, page, pageSize, searchQuery) => {
    setLoading(true)
    try {
      const pageValues = {
        exchange: selectedLabel,
        page: page,
        pageSize: pageSize,
        searchPattern: searchQuery,
      };
      const zTokens = await getInstruments(pageValues);
      if (zTokens?.instruments?.length > 0) {
        const nseDataArray = await Promise.all(
          zTokens.instruments.map(async (item) => {
            const status = await getStatus(item.FinancialInstrumentID, item.Exchange);
            return {
              instrumentid: item.FinancialInstrumentID,
              name2: item.Name,
              value: item.LastPrice,
              instrumentType: item.Exchange,
              TradingSymbol: item.TradingSymbol,
              added: status,
            };
          })
        );
  
        setAssetData(nseDataArray);
        setSelectedLabel(zTokens.instruments[0].Exchange);
      }
    } catch (error) {
      console.error("Error in API calls:", error);
    } finally {
      setLoading(false);
    }
  };

  const getStatus = async (id, exchange) => {
    try {
      const response = await bookMarkStatus(id, exchange);
      console.log(response)
      if (response) {
        return response;
      }
    } catch (error) {
      console.error("Error in watchlist status API calls:", error);
    }
  };

  const handleInputClick = () => {
    if (!showCard) {
      updateDataFromSessionStorage();
      setShowCard(true);
    }
  };

  const handleChipClick = (index) => {
    setSelectedChipIndex(index);
    setSelectedLabel(options[index].label);
    setAssetData([]);
    fetchData(options[index].label, page, pageSize, searchQuery);
  };

  return (
    <Grid lg={12} md={12} sm={12} xs={12} xl={12}>
      <MDBox component="form" role="form">
        <Grid top={12} py={"5px"} lg={12} md={12} sm={12} xs={12} xl={12}>
          <form>
            <MDInput
              label="Search stocks"
              fullWidth
              Autocomplete={false}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onClick={handleInputClick}
              ref={inputRef}
              InputProps={{
                endAdornment: (
                  <Search
                    sx={{ color: "#9CAEB8" }}
                    style={{ width: "25px", height: "25px" }}
                  />
                ),
              }}
            />
          </form>
        </Grid>
      </MDBox>

      <Grid lg={12} md={12} sm={12} xs={12} xl={12}>
        {showCard && (
          <Card
            ref={cardRef}
            sx={{
              position: "absolute",
              top: inputRef.current
                ? inputRef.current.offsetTop + inputRef.current.offsetHeight
                : 0,
              left: inputRef.current ? inputRef.current.offsetLeft : 0,
              zIndex: 5,
              borderRadius: "10px",
              width: "37%",
              paddingY: "6px",
              paddingX: "6px",
              width: {
                xl: "37%",
                xs: "90%",
                sm: "92%",
                md: "51%",
                lg: "36%",
              },
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
              maxHeight: "500px",
            }}
          >
            <Grid display={"flex"} gap={"8px"} paddingY={2}>
              {options.map((option, index) => (
                <Chip
                  key={index}
                  size="medium"
                  sx={{ width: "80px" }}
                  label={
                    <MDTypography
                      sx={{
                        fontSize: "13px",
                        color: selectedChipIndex === index ? "White" : "black",
                      }}
                    >
                      {option.label}
                    </MDTypography>
                  }
                  color={selectedChipIndex === index ? "info" : "default"}
                  //variant="outlined"
                  variant={selectedChipIndex === index ? "filled" : "outlined"}
                  onClick={() => handleChipClick(index)}
                />
              ))}
            </Grid>
            {loading ? (
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <CircularProgress color="success" />
              </Grid>
            ) : (
              <Grid sx={{ overflowY: "auto" }}>
                {assetData.map((asset, index) => (
                  <Grid
                    sx={{
                      paddingX: isSmall ? "10px" : "15px",
                      height: "70px",
                      cursor: "pointer",
                    }}
                    key={index}
                  >
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        gap: "10px",
                        textAlign: "center",
                      }}
                    >
                      <Grid
                        sx={{
                          flex: 1,
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "flex-start",
                          justifyContent: "space-between",
                        }}
                        onClick={() =>
                          navigate("/charts", {
                            state: {
                              instrumentId: asset && asset?.instrumentid,
                              instrumentType: asset?.instrumentType,
                              TradingSymbol: asset?.TradingSymbol,
                              isMCX: asset?.instrumentType,
                            },
                          })
                        }
                      >
                        <Grid
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                          }}
                        >
                          <Tooltip title={asset.name2} placement="top" arrow>
                            <MDTypography
                              style={{
                                fontWeight: "500",
                                fontSize: "15px",
                                marginTop: "10px",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                width: isLarge ? "80%" : "100%",
                                textAlign: "Left",
                              }}
                            >
                              {asset.name2.length > 25
                                ? `${asset.name2.slice(0, 25)}...`
                                : asset.name2}
                            </MDTypography>
                          </Tooltip>
                          <MDTypography
                            style={{ fontWeight: "500", fontSize: "11px" }}
                          >
                            {asset.TradingSymbol}
                          </MDTypography>
                        </Grid>
                        <MDTypography
                          style={{
                            fontWeight: "600",
                            fontSize: "15px",
                            marginTop: "10px",
                          }}
                        >
                          {formatAndMultiplyValueAssetItem(
                            asset.value,
                            asset.instrumentType
                          )}
                        </MDTypography>
                      </Grid>
                      <Grid>
                        <IconButton
                          onClick={() =>
                            handleBookmark(
                              asset.added,
                              asset.instrumentid,
                              asset.instrumentType
                            )
                          }
                          sx={{
                            padding: "5px",
                            marginTop: "5px",
                          }}
                        >
                          {asset.added ? (
                            <BookmarkAddedRoundedIcon
                              color="success"
                              sx={{
                                height: 25,
                                width: 25,
                                padding: "0",
                              }}
                            />
                          ) : (
                            <BookmarkBorderRoundedIcon
                              color="success"
                              sx={{
                                height: 25,
                                width: 25,
                                padding: "0",
                                strokeWidth: 1,
                              }}
                            />
                          )}
                        </IconButton>
                      </Grid>
                    </Grid>
                    <Divider />
                  </Grid>
                ))}
              </Grid>
            )}
          </Card>
        )}
      </Grid>
    </Grid>
  );
};

export default SearchBar;
