import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {
  Grid,
  useMediaQuery,
  useTheme as T,
  IconButton,
  Menu,
  MenuItem,
  Autocomplete,
  TextField,
} from "@mui/material";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import { jwtDecode } from "jwt-decode";
import { DataGrid } from "@mui/x-data-grid";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { getTrades } from "../../services/transactionApi";
import TablePagination from "@mui/material/TablePagination";
import Tooltip from "@mui/material/Tooltip";
import colors from "assets/theme/base/colors";
import { useMaterialUIController } from "context";
import { calenderOptions } from "utils/Objects";
import { formatDateTime } from "utils/formatDateTime";
import { formatAndMultiplyValueAssetItem } from "utils/formatAndMultiplyValue";

function TradeHistory() {
  dayjs.extend(customParseFormat);
  const t = T();
  const isMedium = useMediaQuery(t.breakpoints.down("xxl"));
  const today = dayjs();
  const [start, setStart] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [end, setEnd] = useState(null);
  const [trade, SetTrade] = useState([]);
  const [ids, setId] = useState();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [count, setCount] = useState(0);
  const [options, setOptions] = useState([]);
  const [market, setMarket] = useState(null);
  const [loading, setLoading] = useState(false);
  const { black, white } = colors;
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterOption, setFilterOption] = useState(calenderOptions[0].id);
  const open = Boolean(anchorEl);

  useEffect(() => {
    const enabled = sessionStorage.getItem("enabledmarkets");
    if (enabled) {
      const markets = JSON.parse(enabled);
      let formattedOptions = markets.map((market) => ({
        label: market,
        value: market,
      }));
      formattedOptions.unshift({ label: "ALL", value: "ALL" });
      setOptions(formattedOptions);
      if (formattedOptions.length > 0) {
        setMarket(formattedOptions[0]);
      }
    }
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (!token) return false;
    const decodedToken = jwtDecode(token);
    setId(decodedToken.UserID);
  }, []);

  const tradeData = async (id, start, end, page, page_size, market) => {
    setLoading(true);
    try {
      let formattedStartDate = start;
      let formattedEndDate = end;

      if (filterOption === "today") {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1);
        formattedStartDate = today.toISOString().split("T")[0];
        formattedEndDate = "";
      } else if (filterOption === "week") {
        const endDate = new Date();
        endDate.setDate(endDate.getDate() + 1);
        const startDate = new Date();
        startDate.setDate(endDate.getDate() - 7);
        formattedStartDate = startDate.toISOString().split("T")[0];
        formattedEndDate = endDate.toISOString().split("T")[0];
      } else if (filterOption === "month") {
        const endDate = new Date();
        endDate.setDate(endDate.getDate() + 1);
        const startDate = new Date();
        startDate.setMonth(endDate.getMonth() - 1);
        formattedStartDate = startDate.toISOString().split("T")[0];
        formattedEndDate = endDate.toISOString().split("T")[0];
      } else if (filterOption === "3months") {
        const endDate = new Date();
        endDate.setDate(endDate.getDate() + 1);
        const startDate = new Date();
        startDate.setMonth(endDate.getMonth() - 3);
        formattedStartDate = startDate.toISOString().split("T")[0];
        formattedEndDate = endDate.toISOString().split("T")[0];
      } else if (filterOption === "custom") {
        formattedStartDate = start;
        formattedEndDate = end;
      } else {
        formattedStartDate = null;
        formattedEndDate = null;
      }
      const data = await getTrades(
        id,
        formattedStartDate,
        formattedEndDate,
        page + 1,
        page_size,
        market
      );
      SetTrade(data?.data || []);
      setCount(data?.count);
    } catch (error) {
      console.error("Error fetching Trades: ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleStartDateChange = (newValue) => {
    setStartDate(newValue ? dayjs(newValue).format("YYYY-MM-DD") : null);
  };

  const handleEndDateChange = (newValue) => {
    setEndDate(newValue ? dayjs(newValue).format("YYYY-MM-DD") : null);
    const adjustedEndDate = newValue
      ? dayjs(newValue).add(1, "day").format("YYYY-MM-DD")
      : null;
    setEnd(adjustedEndDate);
  };
  useEffect(() => {
    if (page !== undefined && pageSize !== undefined && ids !== undefined) {
      tradeData(ids, startDate, end, page, pageSize, market);
    }
  }, [startDate, end, page, pageSize, ids, filterOption, market]);

  const dateOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  const handleChange = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };
  const GridHeader = ({ text }) => (
    <MDTypography
      sx={{ fontWeight: 550, paddingLeft: "18px", fontSize: "18px" }}
    >
      {text}
    </MDTypography>
  );

  const transcationColumns = [
    {
      field: "instrument_name",
      headerName: <GridHeader text="Name" />,
      width: isMedium ? 250 : undefined,
      flex: isMedium ? undefined : 1.5,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <Tooltip title={params.value} enterDelay={500}>
          <MDTypography
            sx={{
              paddingLeft: "20px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              paddingTop: "5px",
              fontSize: "18px",
            }}
          >
            {params.value}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      field: "quantity",
      headerName: <GridHeader text="Qty" />,
      width: isMedium ? 80 : undefined,
      flex: isMedium ? undefined : 1,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <MDTypography
          sx={{ paddingLeft: "20px", paddingTop: "5px", fontSize: "18px" }}
        >
          {params.value}
        </MDTypography>
      ),
    },
    {
      field: "price",
      headerName: <GridHeader text=" Unit Price" />,
      width: isMedium ? 130 : undefined,
      flex: isMedium ? undefined : 0.9,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <MDTypography
          color={params.row.buy ? "success" : "error"}
          sx={{
            paddingLeft: "20px",
            fontWeight: 500,
            paddingTop: "5px",
            fontSize: "18px",
          }}
        >
          {formatAndMultiplyValueAssetItem(params.value, params.row.exchange)}
        </MDTypography>
      ),
    },
    {
      field: "charges",
      headerName: <GridHeader text="Brokerage" />,
      width: isMedium ? 150 : undefined,
      flex: isMedium ? undefined : 1,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <MDTypography
          sx={{
            paddingLeft: "20px",
            fontWeight: 500,
            paddingTop: "5px",
            fontSize: "18px",
          }}
        >
          {formatAndMultiplyValueAssetItem(params.value, params.row.exchange)}
        </MDTypography>
      ),
    },
    {
      field: "total_stock",
      headerName: <GridHeader text="Total Price" />,
      width: isMedium ? 150 : undefined,
      flex: isMedium ? undefined : 1,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <MDTypography
          color={params.row.buy ? "success" : "error"}
          sx={{
            paddingLeft: "20px",
            fontWeight: 500,
            paddingTop: "5px",
            fontSize: "18px",
          }}
        >
          {formatAndMultiplyValueAssetItem(params.value, params.row.exchange)}
        </MDTypography>
      ),
    },

    {
      field: "exchange",
      headerName: <GridHeader text="Exchange" />,
      width: isMedium ? 130 : undefined,
      flex: isMedium ? undefined : 1,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <MDTypography
          sx={{ paddingLeft: "20px", paddingTop: "5px", fontSize: "18px" }}
        >
          {params.value}
        </MDTypography>
      ),
    },
    {
      field: "buy",
      headerName: <GridHeader text="Type" />,
      width: isMedium ? 100 : undefined,
      flex: isMedium ? undefined : 0.5,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <MDTypography
          color={params.value ? "success" : "error"}
          sx={{
            paddingLeft: "20px",
            fontWeight: 500,
            paddingTop: "5px",
            fontSize: "18px",
          }}
        >
          {params.value ? "BUY" : "SELL"}
        </MDTypography>
      ),
    },
    {
      field: "created_at",
      headerName: <GridHeader text="Transaction Time" />,
      width: isMedium ? 270 : undefined,
      flex: isMedium ? undefined : 1.7,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <Tooltip
          title={new Intl.DateTimeFormat("en-US", dateOptions).format(
            new Date(params.value)
          )}
          enterDelay={500}
        >
          <MDTypography
            sx={{
              paddingLeft: "20px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              paddingTop: "5px",
              fontSize: "18px",
            }}
          >
            {formatDateTime(params.value)}
          </MDTypography>
        </Tooltip>
      ),
    },
  ];

  function customNoRows() {
    return (
      <MDBox
        height={"100%"}
        width={"100%"}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <MDTypography sx={{}}>No Trades Found!</MDTypography>
      </MDBox>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
     <Grid xl={12} lg={12} md={12} sm={12} xs={12} marginTop={2} container>
     <Grid
        xs={12}
        sm={12}
        lg={12}
        md={12}
        xl={12}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <Grid
          xs={3}
          sm={2}
          lg={1}
          md={1}
          xl={0.8}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton onClick={handleClick} id="account-menu" data-testid="filter-icon-button">
            <EditCalendarIcon
              fontSize="large"
              sx={{ color: darkMode ? white.main : "default" }}
            />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  left: "50%",
                  width: 10,
                  height: 10,
                  bgcolor: darkMode ? "inherit" : white.main,
                  transform: "translateX(-77%) translateY(32%)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "center", vertical: "top" }}
          >
            {calenderOptions.map((option) => (
              <MenuItem
                key={option.id}
                onClick={() => {
                  setFilterOption(option.id), handleClose();
                }}
                selected={option.id === filterOption}
              >
                {option.label}
              </MenuItem>
            ))}
          </Menu>
        </Grid>
        <Grid lg={3} xl={1.8} sm={3} md={3} xs={6} sx={{marginRight: filterOption === "custom" ? "15px":"0px"}}>
          <Autocomplete
            value={market}
            onChange={(event, newValue) => {
              setMarket(newValue);
            }}
            options={options}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  "& .MuiInputBase-root": { height: "44px", marginTop: "5px" },
                }}
              />
            )}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
          />
        </Grid>
        {filterOption === "custom" && (
          <Grid
          xs={12} sm={12} lg={7} md={7} xl={5}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            <Grid xs={12} sm={12} lg={5.7} md={5.7} xl={5.8}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="From"
                      slotProps={{
                        field: { clearable: true },
                        openPickerButton: {
                          color: darkMode ? "white" : "muted",
                        },
                      }}
                      value={dayjs(startDate)}
                      maxDate={today}
                      onChange={(newValue) => (
                        setStart(newValue), handleStartDateChange(newValue)
                      )}
                      renderInput={(params) => <TextField {...params} />}
                    />
              </LocalizationProvider>
            </Grid>
            <Grid xs={12} sm={12} lg={5.7} md={5.7} xl={5.8}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="To"
                      slotProps={{
                        field: { clearable: true },
                        openPickerButton: {
                          color: darkMode ? "white" : "muted",
                        },
                      }}
                      value={dayjs(endDate)}
                      minDate={
                        startDate ? dayjs(startDate).add(1, "day") : null
                      }
                      maxDate={today}
                      onChange={(newValue) => handleEndDateChange(newValue)}
                      renderInput={(params) => <TextField {...params} />}
                    />
              </LocalizationProvider>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid  lg={12} md={12} sm={12} xs={12} xl={12}>
        <Grid
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          marginTop={"20px"}
        >
          <DataGrid
            disableColumnMenu
            rows={trade}
            loading={loading}
            columns={transcationColumns}
            disableRowSelector={true}
            disableRowSelectionOnClick
            rowHeight={45}
            slots={{
              noRowsOverlay: customNoRows,
            }}
            disableColumnResize
            sortModel={[
              {
                field: "created_at",
                sort: "desc",
              },
            ]}
            getRowId={(data) => data.id}
            autoHeight
            sx={{
              cursor: "pointer",
              maxWidth: "99%",
              "& .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-cell:focus-within": {
                outline: "none",
              },
              "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within": {
                outline: "none",
              },
            }}
            hideFooterPagination={true}
            hideFooter={true}
          />
          <MDBox display={"flex"} justifyContent={"flex-end"}>
            <TablePagination
              component="div"
              count={count}
              page={page}
              onPageChange={handleChange}
              rowsPerPage={pageSize}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                color: darkMode ? white.main : black.main,
              }}
            />
          </MDBox>
        </Grid>
      </Grid>
     </Grid>
    </DashboardLayout>
  );
}

export default TradeHistory;
