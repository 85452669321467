import React, { useState, useEffect } from "react";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import colors from "assets/theme/base/colors";
import {
  Modal,
  Card,
  TextField,
  RadioGroup,
  Radio,
  Grid,
  FormControlLabel,
  FormHelperText,
  useMediaQuery,
  useTheme as T,
} from "@mui/material";
import {
  sellAndBuySymbolApi,
  userConfig,
  getMargin,
} from "../../../services/portifolioapis";
// import moment from "moment";
import moment from "moment-timezone";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getBalances } from "services/profileapis";
import { formatValue } from "utils/formatValue";
import { makeOrder } from "services/transactionApi";
import { useNavigate } from "react-router-dom";
import { marketTimings } from "services/userApis";
import MDInput from "components/MDInput";
import { cryptoFormat } from "utils/cryptoFormat";
import { formatAndMultiplyValueAssetItem } from "utils/formatAndMultiplyValue";
import showToast from "utils/toastUtils";

function buyandsell({
  instrumentId,
  setQData,
  price,
  Name,
  askPrice,
  bidPrice,
  getQuantity,
  quant,
  instrumentType,
  modifyOrderType,
  modifyType,
  modifyPrice,
  modifyQuantity,
  orderId,
  upperCircuit,
  lowerCircuit,
  selectedOption,
}) {
  const [openModal, setOpenModal] = useState(false);
  const [buyType, setBuyType] = useState(false);
  const [sellType, setSellType] = useState(false);
  const [quantity, setQuantity] = useState("");
  const [disabledTimes, setDisabledTimes] = useState([]);
  const [Leverage, setLeverage] = useState(0);
  const [Brokerage, setBrokerage] = useState(0);
  const [marketValues, setMarketValues] = useState({});
  const [total, setTotal] = useState(0);
  const [isDisable, setIsDisable] = useState(false);
  const [Balance, setBalance] = useState(0);
  const token = sessionStorage.getItem("token");
  const [value, setValue] = useState("market");
  const { white, black } = colors;
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [shouldRemove, setShouldRemove] = useState(false);
  const [limitValue, setLimitValue] = useState();
  const [stopLossValue, setStopLossValue] = useState();
  const [message, setMessage] = useState("");
  const [showMarket, setShowMarket] = useState(true);
  const [margin, setMargin] = useState(0.7);
  const [adjustedPrice, setAdjustedPrice] = useState(0);
  const [timings, setTimings] = useState([]);
  const [quantityError, setQuantitiyError] = useState(false);
  const [totalBr, setTotalBr] = useState(0);
  const navigate = useNavigate();
  const t = T()
  const isSmall = useMediaQuery(t.breakpoints.down("md"));

  // console.log('user selected tab:---', selectedOption)
  // console.log('instrument Id:---', instrumentId)

  const textValue = `${
    buyType === false
      ? formatAndMultiplyValueAssetItem(bidPrice, instrumentType)
      : formatAndMultiplyValueAssetItem(askPrice, instrumentType)
  }`;

  useEffect(() => {
    if (modifyOrderType || modifyPrice || modifyType || modifyQuantity) {
      setOpenModal(true);
      setSellType(!modifyType);
      setBuyType(modifyType);
      setShowMarket(false);

      if (modifyOrderType === "LIMIT") {
        setLimitValue(modifyPrice);
        setValue("limit");
      } else if (modifyOrderType === "STOPLOSS") {
        setStopLossValue(modifyPrice);
        setValue("stopLoss");
      }
    }
  }, [modifyOrderType, modifyPrice, modifyType, modifyQuantity]);

  useEffect(() => {
    const newQuantity = parseFloat(modifyQuantity);
    if (!isNaN(newQuantity) && modifyQuantity) {
      setQuantity(newQuantity);
    }
  }, [modifyQuantity]);

  const shouldDisableButton = (
    limitValue,
    bidPrice,
    askPrice,
    sellType,
    value,
    stopLossValue,
    upperCircuit,
    lowerCircuit,
    instrumentType
  ) => {
    const bidPriceNum = parseFloat(bidPrice);
    const askPriceNum = parseFloat(askPrice);

    const BidLimit = Math.ceil(askPriceNum - askPriceNum * 0.001);
    const askLimit = Math.ceil(bidPriceNum + bidPriceNum * 0.001);

    const bidStopLossLimit = Math.ceil(askPriceNum + askPriceNum * 0.001);
    const askStopLossLimit = Math.ceil(bidPriceNum - bidPriceNum * 0.001);

    // For crypto

    const cryptoAskPrice = cryptoFormat(bidPrice); //Sell
    const cryptoBidPrice = cryptoFormat(askPrice); //Buy

    const cryptoBidLimit = parseFloat(cryptoBidPrice - cryptoBidPrice * 0.001); //Limit Buy
    const cryptoAskLimit = parseFloat(cryptoAskPrice + cryptoAskPrice * 0.001); //Limit sell

    const cryptoBidStopLossLimit = parseFloat(
      //stpoLoss Buy
      cryptoBidPrice + cryptoBidPrice * 0.001
    );
    const cryptoAskStopLossLimit = parseFloat(
      //StopLoss Sell
      cryptoAskPrice - cryptoAskPrice * 0.001
    );

    if (value === "market") {
      if (sellType) {
        if (bidPrice <= 0 || bidPrice === undefined) {
          setMessage("");
          return false;
        } else {
          setMessage("");
          return true;
        }
      } else {
        if (askPrice <= 0 || askPrice === undefined) {
          setMessage("");
          return false;
        } else {
          setMessage("");
          return true;
        }
      }
    } else if (value === "stopLoss") {
      if (sellType) {
        // console.log("ask Stop Loss value:---", askStopLossLimit);
        // console.log("stop Loss value:---", stopLossValue);
        // console.log("lowerCircuit:---", lowerCircuit);
        // function for validation message
        if (instrumentType === "CRYPTO") {
          if (parseFloat(stopLossValue) <= cryptoAskStopLossLimit) {
            setMessage("");
          } else {
            setMessage(`price should be less than ${formatAndMultiplyValueAssetItem(cryptoAskStopLossLimit, instrumentType)}`);
          }
          // return value for disable button
          return (
            stopLossValue <= cryptoAskStopLossLimit &&
            cryptoAskStopLossLimit > 1
          );
        } else {
          if (
            parseFloat(stopLossValue) <= askStopLossLimit &&
            parseFloat(stopLossValue) >= lowerCircuit
          ) {
            setMessage("");
          } else {
            setMessage(
              `Enter price between ${formatAndMultiplyValueAssetItem(lowerCircuit || 0, instrumentType)} and ${formatAndMultiplyValueAssetItem(askStopLossLimit, instrumentType)
              }`
            );
          }
          // return value for disable button
          return (
            stopLossValue <= askStopLossLimit &&
            stopLossValue >= lowerCircuit &&
            lowerCircuit > 1 &&
            askStopLossLimit > 1
          );
        }
      } else {
        // console.log("bid Stop Loss value:---", bidStopLossLimit);
        // console.log("stop Loss value:---", stopLossValue);
        // console.log("upperCircuit:---", upperCircuit);
        // function for validation message
        if (instrumentType === "CRYPTO") {
          if (parseFloat(stopLossValue) >= cryptoBidStopLossLimit) {
            setMessage("");
          } else {
            setMessage(`price should be more then ${formatAndMultiplyValueAssetItem(cryptoBidStopLossLimit, instrumentType)}`);
          }
          // return value for disable button
          return (
            stopLossValue >= cryptoBidStopLossLimit &&
            cryptoBidStopLossLimit > 0.0
          );
        } else {
          if (
            parseFloat(stopLossValue) >= bidStopLossLimit &&
            parseFloat(stopLossValue) <= upperCircuit
          ) {
            setMessage("");
          } else {
            setMessage(
              `Enter price between ${formatAndMultiplyValueAssetItem(bidStopLossLimit, instrumentType)} and ${formatAndMultiplyValueAssetItem(upperCircuit || 0, instrumentType)}`
            );
          }
          // return value for disable button
          return (
            stopLossValue >= bidStopLossLimit &&
            stopLossValue <= upperCircuit &&
            upperCircuit > 1 &&
            bidStopLossLimit > 1
          );
        }
      }
    } else {
      if (sellType) {
        // console.log("ask limit value:---", askLimit);
        // console.log("Limit Value:---", limitValue);
        // console.log("lowerCircuit:---", lowerCircuit);
        // function for validation message
        if (instrumentType === "CRYPTO") {
          if (parseFloat(limitValue) >= cryptoAskLimit) {
            setMessage("");
          } else {
            setMessage(`price should be more than ${formatAndMultiplyValueAssetItem(cryptoAskLimit, instrumentType)}`);
          }
          // return value for disable button
          return limitValue >= cryptoAskLimit && cryptoAskLimit > 0.0;
        } else {
          if (
            parseFloat(limitValue) >= askLimit &&
            parseFloat(limitValue) <= upperCircuit
          ) {
            setMessage("");
          } else {
            setMessage(`Enter price between ${formatAndMultiplyValueAssetItem(askLimit, instrumentType)} and ${formatAndMultiplyValueAssetItem(upperCircuit ||0 , instrumentType)}`);
          }
          // return value for disable button
          return (
            limitValue >= askLimit &&
            limitValue <= upperCircuit &&
            upperCircuit > 1 &&
            askLimit > 1
          );
        }
      } else {
        // console.log("bid limit value:---", BidLimit);
        // console.log("Limit Value:---", limitValue);
        // console.log("upperCircuit:---", upperCircuit);
        // function for validation message
        if (instrumentType === "CRYPTO") {
          if (
            parseFloat(limitValue) > 0 &&
            parseFloat(limitValue) <= cryptoBidLimit
          ) {
            setMessage("");
          } else {
            setMessage(`Enter price should be between $0.00 and ${formatAndMultiplyValueAssetItem(cryptoBidLimit, instrumentType)}`);
          }
          // return value for disable button
          return (
            limitValue <= cryptoBidLimit && cryptoBidLimit > 0 && limitValue > 0
          );
        } else {
          if (
            parseFloat(limitValue) <= BidLimit &&
            parseFloat(limitValue) >= lowerCircuit
          ) {
            setMessage("");
          } else {
            setMessage(`Enter price between ${formatAndMultiplyValueAssetItem(lowerCircuit || 0, instrumentType)} and ${formatAndMultiplyValueAssetItem(BidLimit, instrumentType)}`);
          }
          // return value for disable button
          return (
            limitValue <= BidLimit &&
            limitValue >= lowerCircuit &&
            lowerCircuit > 1 &&
            BidLimit > 1
          );
        }
      }
    }
  };

  useEffect(() => {
    setIsDisable(
      !shouldDisableButton(
        limitValue,
        bidPrice,
        askPrice,
        sellType,
        value,
        stopLossValue,
        upperCircuit,
        lowerCircuit,
        instrumentType
      )
    );
  }, [
    limitValue,
    bidPrice,
    askPrice,
    sellType,
    value,
    stopLossValue,
    upperCircuit,
    lowerCircuit,
    instrumentType,
  ]);

  const handleChange = (event) => {
    // console.log("seleted Radio:----", event.target.value);
    setValue(event.target.value);
    setQuantity("");
    setLimitValue("");
    setStopLossValue("");
    setQuantitiyError(false);
    if (event.target.value === "limit") {
      setLimitValue("");
    } else if (event.target.value === "stopLoss") {
      setStopLossValue("");
    }
  };

  const handleQuantityChange = (event) => {
    let decimalValue = event.target.value;
    setQuantity(parseFloat(decimalValue));
  };

  const fetchTimings = async () => {
    try {
      const response = await marketTimings();
      // console.log("market timings data from api:", response);
      setTimings(response);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchTimings();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const marginData = await getMargin(instrumentId, instrumentType);
        // console.log("Margin Data:", marginData);
        if (marginData < 1) {
          setMargin(marginData);
        } else {
          setMargin(1);
        }
      } catch (error) {
        console.error("Error fetching margin data:", error);
      }
    };

    if (instrumentId && instrumentType) {
      fetchData();
    }
  }, [instrumentId, instrumentType]);

  const performTransaction = async (orderType) => {
    try {
      if (instrumentType === "CRYPTO" && (!quantity || isNaN(quantity))) {
        showToast("Quantity is not  valid", "warning");
        setQuantitiyError(true);
        return;
      } else if (
        instrumentType !== "CRYPTO" &&
        (!quantity ||
          isNaN(quantity) ||
          parseFloat(quantity) <= 0 ||
          !Number.isInteger(parseFloat(quantity)))
      ) {
        showToast("Quantity is not a valid", "error");
        setQuantitiyError(true);
        return;
      } else {
        setQuantitiyError(false);
      }

      if (showMarket || selectedOption === "Executed") {
        let data;

        const OrderStatus =
          value === "market" ? (orderType === "buy" ? "Buy" : "Sell") : "Order";
        const price =
          value === "market"
            ? orderType === "buy"
              ? askPrice
              : bidPrice
            : value === "limit"
            ? limitValue
            : stopLossValue;

        if (value === "market") {
          data = {
            instrumentId: parseInt(instrumentId),
            Exchange: instrumentType,
            quantity: quantity,
            orderType: "MARKET",
            buy: orderType == "buy" ? true : false,
          };
        } else if (value === "stopLoss") {
          data = {
            instrumentId: parseInt(instrumentId),
            Exchange: instrumentType,
            quantity: quantity,
            orderType: "STOPLOSS",
            buy: orderType == "buy" ? true : false,
            triggerPrice: parseFloat(stopLossValue),
          };
        } else {
          data = {
            instrumentId: parseInt(instrumentId),
            Exchange: instrumentType,
            quantity: quantity,
            orderType: "LIMIT",
            buy: orderType == "buy" ? true : false,
            triggerPrice: parseFloat(limitValue),
          };
        }

        // console.log("Data being sent to API:", data);
        const responseData = await sellAndBuySymbolApi(data, token);
        if (responseData.success) {
          showToast(
            `${OrderStatus} successfull for Qty:${quantity} at ${
            formatAndMultiplyValueAssetItem(price, instrumentType)
            }`,
            "success"
          );
          // fetchData1();
          fetchBalances();
          handleCloseModal();
          getQuantity();
        } else {
          showToast(`${responseData.error}`, "error");
        }
      } else {
        9;
        const OrderStatus = "market" ? "Buy" : "Order";
        const price =
          value === "market"
            ? orderType === "buy"
              ? parseFloat(askPrice).toFixed(2)
              : parseFloat(askPrice).toFixed(2)
            : value === "limit"
            ? limitValue
            : stopLossValue;
        const data = {
          exchange: instrumentType,
          orderId: parseInt(orderId),
          triggerPrice:
            value === "limit"
              ? parseFloat(limitValue)
              : parseFloat(stopLossValue),
          quantity: parseInt(quantity),
        };

        const response = await makeOrder(data);
        if (response.status === "success") {
          showToast(
            `${OrderStatus} successfull for Qty:${quantity} at ${
              formatAndMultiplyValueAssetItem(price, instrumentType)
            }`, "success"
          );
          // fetchData1();
          fetchBalances();
          handleCloseModal();
          getQuantity();
        } else {
         showToast(`${response.error}`, );
        }
      }
    } catch (err) {
      console.error(err);
      showToast(
        `${err.message || "An error occurred during the transaction."}`,
        "error"
      );
    }
  };

  useEffect(() => {
    fetchBalances();
    const isActiveDisable = localStorage.getItem("isActive") === "true";
    setShouldRemove(isActiveDisable);
  }, []);

  const fetchBalances = async () => {
    try {
      const response = await getBalances();
      // console.log("data----====-----====>", response);
      const cond =
        instrumentType === "NSE" || instrumentType === "NFO"
          ? "NSE"
          : instrumentType;
      const balanceExchange = response.wallets.find(
        (wallet) => wallet.exchange === cond
      );
      const netBalance = balanceExchange.net_balance;
      setBalance(netBalance.toFixed(2));
    } catch (error) {
      console.error("Leverage:", error);
    }
  };

  useEffect(() => {
    fetchDatabutton();
  }, []);

  const fetchDatabutton = async () => {
    try {
      const token = sessionStorage.getItem("token");
      if (!token) {
        console.error("Trouble getting token from session storage");
      }
      const response = await fetch(
        `${process.env.REACT_APP_FAST_API_URL}/holidays/by-type/?type=${instrumentType}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      // console.log(data);

      // const disabledTimes = data.map((item) => {
      //   if (item.market === "NASDAQ") {
      //     let startTime = moment.tz(item.starttime, "HH:mm:ss", item.timezone);
      //     let endTime = moment.tz(item.endtime, "HH:mm:ss", item.timezone);
      //     startTime = startTime.tz("America/New_York").tz("Asia/Kolkata");
      //     endTime = endTime
      //       .tz("America/New_York")
      //       .subtract(1, "second")
      //       .tz("Asia/Kolkata");
      //     return {
      //       exchange: item.market,
      //       date: moment(item.date).format("YYYY-MM-DD"),
      //       startTime: startTime.format("HH:mm:ss"),
      //       endTime: endTime.format("HH:mm:ss"),
      //     };
      //   } else {
      //     return {
      //       exchange: item.market,
      //       date: moment(item.date).format("YYYY-MM-DD"),
      //       startTime: moment(item.starttime, "HH:mm:ss")
      //           .utcOffset("+05:30")
      //           .format("HH:mm:ss"),
      //       endTime: moment(item.endtime, "HH:mm:ss").format("HH:mm:ss"),
      //     };
      //   }
      // });

      const disabledTimes = data.map((item) => ({
        date: moment(item.date).format("YYYY-MM-DD"),
        startTime: moment(item.starttime, "HH:mm:ss")
          .utcOffset("+05:30")
          .format("HH:mm:ss"),
        endTime: moment(item.endtime, "HH:mm:ss").format("HH:mm:ss"),
      }));
      setDisabledTimes(disabledTimes);
      // console.log("Dates", disabledTimes);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const isWithinTimeLimit = () => {
    const currentDate = moment().format("YYYY-MM-DD");
    const currentTime = moment();

    for (const { date, startTime, endTime } of disabledTimes) {
      if (
        currentDate === date &&
        currentTime.isBetween(startTime, endTime, null, "[]")
      ) {
        return false;
      }
    }
    return true;
  };

  const shouldDisable = () => {
    const currentDate = moment().format("YYYY-MM-DD");
    const currentTime = moment();

    for (const { date, startTime, endTime } of disabledTimes) {
      if (currentDate === date) {
        const startMoment = moment(startTime, "HH:mm:ss");
        const endMoment = moment(endTime, "HH:mm:ss");

        if (currentTime.isBetween(startMoment, endMoment, null, "[]")) {
          console.log("Button should be disabled for date:", date);
          return true;
        }
      }
    }

    return false;
  };

  const disableTrade = () => {
    const market = timings.find(
      (exchange) => exchange.exchange === instrumentType
    );

    if (!market) return false;

    const presentTime = moment();
    const startTime = moment(market.start_time, "HH:mm:ss");
    const endTime = moment(market.end_time, "HH:mm:ss");

    if (endTime.isBefore(startTime)) {
      const endOfDay = moment("23:59:59", "HH:mm:ss");
      const startOfDay = moment("00:00:00", "HH:mm:ss");
      return !(
        presentTime.isBetween(startTime, endOfDay, null, "[]") ||
        presentTime.isBetween(startOfDay, endTime, null, "[]")
      );
    } else {
      return !presentTime.isBetween(startTime, endTime, null, "[]");
    }
  };

  const isButtonDisabled = () => {
    return shouldDisable() || disableTrade();
  };

  const handleOpenModal = (type) => {
    if (isWithinTimeLimit()) {
      setOpenModal(true);
      if (type === "buy") {
        setBuyType(true);
        setSellType(false);
      } else {
        setSellType(true);
        setBuyType(false);
      }
    } else {
      alert("Sorry, the button is disabled.");
    }
  };

  const handleCloseModal = () => {
    if (!showMarket) {
      setTimeout(() => {
        navigate("/Orders", { replace: true });
      }, 1000);
    }
    setQuantity("");
    setOpenModal(false);
    setValue("market");
    setLimitValue("");
    setStopLossValue("");
    setQuantitiyError(false);
  };

  const fetchUserConfig = async () => {
    try {
      const response = await userConfig();
      const cond =
        instrumentType === "NSE" || instrumentType === "NFO"
          ? "NSE"
          : instrumentType;
      const userData = response.config[cond];
      // console.log("userConfg--------", userData);
      setMarketValues(userData);
      setLeverage(userData.leverage);
      setTotalBr(userData.totalBrokerage);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchUserConfig();
  }, [instrumentId]);

  useEffect(() => {
    brokerage(quantity);
  }, [
    buyType,
    quantity,
    value,
    limitValue,
    stopLossValue,
    askPrice,
    bidPrice,
    totalBr,
  ]);

  const brokerage = (quantity) => {
    const parsedQuantity = parseFloat(quantity);
    // console.log('brokerage calci quantity', quantity)
    if (!isNaN(parsedQuantity)) {
      let filteredValue;
      if (value === "market") {
        filteredValue = buyType === false ? bidPrice : askPrice;
        const maskedValue = buyType === false ? bidPrice : askPrice;
        const marginAppliedValue =
          margin < 1 ? maskedValue * margin : maskedValue;
        setAdjustedPrice(marginAppliedValue);
      } else if (value === "stopLoss") {
        filteredValue = stopLossValue ||0;
        // console.log('brokerage calci stop loss price', stopLossValue)
        const marginAppliedValue =
          margin < 1 ? stopLossValue * margin : stopLossValue;
        setAdjustedPrice(marginAppliedValue);
      } else {
        filteredValue = limitValue || 0;
        // console.log('brokerage calci limit price', limitValue)
        const marginAppliedValue =
          margin < 1 ? limitValue * margin : limitValue;
        setAdjustedPrice(marginAppliedValue);
      }
      const calculated = parseFloat(filteredValue) * quantity;
      // console.log('calculated p * q', calculated)
      const brokerage = calculated * totalBr;
      // console.log('calculated brokerage', `${calculated} * ${totalBr}=${brokerage.toFixed(2)}`)
      const brokerages = calculated + brokerage;
      // console.log('calculated total brokerages', `${calculated} + ${brokerage.toFixed(2)}=${brokerages.toFixed(2)}`)
      setBrokerage(brokerage||0)
      setTotal(brokerages || 0)
    } else {
      setBrokerage(0);
      setTotal(0);
    }
  };

  const getMarginValue = (
    value,
    margin,
    askPrice,
    bidPrice,
    limitValue,
    stopLossValue
  ) => {
    if (value === "market") {
      const maskedValue = buyType === false ? bidPrice : askPrice;
      const marginAppliedValue =
        margin < 1 ? maskedValue * margin : maskedValue;
      return marginAppliedValue;
    } else if (value === "stopLoss") {
      const marginAppliedValue =
        margin < 1 ? stopLossValue * margin : stopLossValue;
      return marginAppliedValue;
    } else {
      const marginAppliedValue = margin < 1 ? limitValue * margin : limitValue;
      return marginAppliedValue;
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      return e.preventDefault();
    }
  };

  return (
    <Grid>
      {shouldRemove && (
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: "20px",
            paddingX: 4,
            paddingTop: 5,
          }}
        >
          <Grid item lg={5.8} sm={5.8} xs={10} sx={{ paddingTop: 6 }}>
            <MDButton
              variant="gradient"
              disabled={isButtonDisabled()}
              color={buyType ? "success" : "success"}
              onClick={() => handleOpenModal("buy")}
              fullWidth
            >
              Buy
            </MDButton>
          </Grid>
          <Grid lg={5.8} sm={5.8} xs={10} sx={{ paddingTop: 6 }}>
            <MDButton
              variant="gradient"
              disabled={isButtonDisabled()}
              color={sellType ? "error" : "error"}
              onClick={() => handleOpenModal("sell")}
              fullWidth
            >
              Sell
            </MDButton>
          </Grid>
        </Grid>
      )}
      <Grid lg={12} md={12} xs={12} xl={12} sm={12}>
        <Modal
          animationType="slide"
          transparent={true}
          open={openModal}
          onClose={handleCloseModal}
        >
          <Grid
            lg={12}
            md={12}
            xs={12}
            xl={12}
            sm={12}
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card
              sx={{
                width: {
                  xs: "95%",
                  sm: "60%",
                  md: "60%",
                  lg: "50%",
                  xl: "30%",
                },
                borderRadius: "15px",
              }}
            >
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingX: 3,
                  paddingTop: 2,
                }}
              >
                <MDTypography sx={{fontSize:'17px'}}>Name</MDTypography>
                <MDTypography sx={{fontSize:'17px'}}>{Name}</MDTypography>
              </Grid>
              {!showMarket && (<Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingX: 3,
                  paddingTop: 2,
                }}
              >
                <MDTypography sx={{fontSize:'17px'}}>Exchange</MDTypography>
                <MDTypography sx={{fontSize:'17px'}}>{instrumentType}</MDTypography>
              </Grid>)}
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingX: 3,
                  paddingTop: 2,
                }}
              >
                <MDTypography sx={{fontSize:'17px'}}>Current Price</MDTypography>
                <MDTypography sx={{fontSize:'17px'}}>
                  {textValue}
                </MDTypography>
              </Grid>
              {showMarket && (
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingX: 3,
                    paddingTop: 2,
                  }}
                >
                  <MDTypography sx={{fontSize:'17px'}}>Quantity</MDTypography>
                  <MDTypography sx={{fontSize:'17px'}}>{quant}</MDTypography>
                </Grid>
              )}
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingX: 3,
                  paddingTop: 2,
                }}
              >
                <MDTypography sx={{fontSize:'17px'}}>Leverage</MDTypography>
                <MDTypography sx={{fontSize:'17px'}}>{Leverage}x</MDTypography>
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingX: 3,
                  paddingTop: 2,
                }}
              >
                <MDTypography sx={{fontSize:'17px'}}>Balance with leverage</MDTypography>
                <MDTypography sx={{fontSize:'17px'}}>
                  {formatAndMultiplyValueAssetItem(Balance, instrumentType)}
                </MDTypography>
              </Grid>
              {showMarket || !selectedOption === "Executed" ? (
                <Grid sx={{ paddingX: 3, paddingTop: 2 }}>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={value}
                    onChange={handleChange}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent:"space-between",
                      width: "100%",
                    }}
                  >
                    <FormControlLabel
                      value="limit"
                      control={<Radio size={isSmall?"small":"medium"} />}
                      label="Limit"
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: isSmall?"15px":"17px",
                          fontWeight: 400,
                        },
                      }}
                    />
                    <FormControlLabel
                      value="stopLoss"
                      control={<Radio size={isSmall?"small":"medium"} />}
                      label="Stop Loss"
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontWeight: 400,
                          fontSize: isSmall?"15px":"17px",
                        },
                      }}
                    />
                    <FormControlLabel
                      value="market"
                      control={<Radio size={isSmall?"small":"medium"} />}
                      label="Market"
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontWeight: 400,
                          fontSize: isSmall?"15px":"17px",
                        },
                      }}
                    />
                  </RadioGroup>
                </Grid>
              ) : (
                <Grid sx={{ paddingX: 3, paddingTop: 2 }}>
                  <RadioGroup
                    value={value}
                    onChange={handleChange}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <FormControlLabel
                      value="limit"
                      control={<Radio size={isSmall?"small":"medium"} />}
                      label="Limit"
                      checked={value === "limit" || modifyOrderType === "LIMIT"}
                      disabled={modifyOrderType === "STOPLOSS"}
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: isSmall?"15px":"17px",
                          fontWeight: 400,
                        },
                      }}
                    />
                    <FormControlLabel
                      value="stopLoss"
                      control={<Radio size={isSmall?"small":"medium"} />}
                      label="Stop Loss"
                      checked={
                        value === "stopLoss" || modifyOrderType === "STOPLOSS"
                      }
                      disabled={modifyOrderType === "LIMIT"}
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: isSmall?"15px":"17px",
                          fontWeight: 400,
                        },
                      }}
                    />
                  </RadioGroup>
                </Grid>
              )}
              {showMarket ? (
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingX: 3,
                    paddingTop: 2,
                  }}
                >
                  <MDTypography sx={{fontSize:'17px'}}>Price</MDTypography>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    {value === "market" ? (
                      <TextField
                        id="price"
                        variant="outlined"
                        disabled={value === "market"}
                        size="small"
                        type="text"
                        placeholder="Value"
                        value={textValue}
                        sx={{
                          fontSize: "20px",
                          width: "170px",
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: darkMode
                              ? white.main
                              : black.main,
                            fontSize: "15px",
                          },
                        }}
                      />
                    ) : value === "stopLoss" ? (
                      <TextField
                        id="stopLoss"
                        variant="outlined"
                        size="small"
                        type="number"
                        placeholder="Stop Loss Value"
                        value={stopLossValue}
                        InputProps={{
                          startAdornment: (
                            <MDTypography
                              style={{
                                fontSize: "17px",
                                color: darkMode ? white.main : black.main,
                              }}
                            >
                              {instrumentType === "NSE" ||
                              instrumentType === "MCX"
                                ? "₹"
                                : "$"}
                            </MDTypography>
                          ),
                        }}
                        onChange={(event) =>
                          setStopLossValue(event.target.value)
                        }
                        sx={{
                          fontSize: "20px",
                          width: "170px",
                          "& .MuiInputBase-input": {
                            WebkitTextFillColor: darkMode
                              ? white.main
                              : black.main,
                            fontSize: "15px",
                          },
                        }}
                      />
                    ) : (
                      <TextField
                        id="limit"
                        variant="outlined"
                        size="small"
                        type="number"
                        placeholder="Value"
                        value={limitValue}
                        InputProps={{
                          startAdornment: (
                            <MDTypography
                              style={{
                                fontSize: "17px",
                                color: darkMode ? white.main : black.main,
                              }}
                            >
                              {instrumentType === "NSE" ||
                              instrumentType === "MCX"
                                ? "₹"
                                : "$"}
                            </MDTypography>
                          ),
                        }}
                        onChange={(event) => setLimitValue(event.target.value)}
                        sx={{
                          fontSize: "20px",
                          width: "170px",
                          "& .MuiInputBase-input": {
                            WebkitTextFillColor: darkMode
                              ? white.main
                              : black.main,
                            fontSize: "15px",
                          },
                        }}
                      />
                    )}
                    <FormHelperText>
                      <MDTypography
                        sx={{ fontSize: "11px", color: "red", fontWeight: 510 }}
                      >
                        {message}
                      </MDTypography>
                    </FormHelperText>
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingX: 3,
                    paddingTop: 2,
                  }}
                >
                  <MDTypography sx={{fontSize:'17px'}}>Price</MDTypography>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    {value === "stopLoss" ? (
                      <TextField
                        id="stopLoss"
                        variant="outlined"
                        size="small"
                        type="number"
                        placeholder="Stop Loss Value"
                        value={stopLossValue}
                        InputProps={{
                          startAdornment: (
                            <MDTypography
                              style={{
                                fontSize: "17px",
                                color: darkMode ? white.main : black.main,
                              }}
                            >
                              {instrumentType === "NSE" ||
                              instrumentType === "MCX"
                                ? "₹"
                                : "$"}
                            </MDTypography>
                          ),
                        }}
                        onChange={(event) =>
                          setStopLossValue(event.target.value)
                        }
                        sx={{
                          fontSize: "20px",
                          width: "170px",
                          "& .MuiInputBase-input": {
                            WebkitTextFillColor: darkMode
                              ? white.main
                              : black.main,
                            fontSize: "15px",
                          },
                        }}
                      />
                    ) : (
                      <TextField
                        id="limit"
                        variant="outlined"
                        size="small"
                        type="number"
                        placeholder="Value"
                        value={limitValue}
                        InputProps={{
                          startAdornment: (
                            <MDTypography
                              style={{
                                fontSize: "17px",
                                color: darkMode ? white.main : black.main,
                              }}
                            >
                              {instrumentType === "NSE" ||
                              instrumentType === "MCX"
                                ? "₹"
                                : "$"}
                            </MDTypography>
                          ),
                        }}
                        onChange={(event) => setLimitValue(event.target.value)}
                        sx={{
                          fontSize: "20px",
                          width: "170px",
                          "& .MuiInputBase-input": {
                            WebkitTextFillColor: darkMode
                              ? white.main
                              : black.main,
                            fontSize: "15px",
                          },
                        }}
                      />
                    )}
                    <FormHelperText>
                      <MDTypography
                        sx={{ fontSize: "11px", color: "red", fontWeight: 510 }}
                      >
                        {message}
                      </MDTypography>
                    </FormHelperText>
                  </Grid>
                </Grid>
              )}
              {margin && margin < 1 && (
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingX: 3,
                    paddingTop: 2,
                  }}
                >
                  <MDTypography sx={{fontSize:'17px'}}>Margin Applied Price</MDTypography>
                  <MDTypography sx={{fontSize:'17px'}}>
                    {" "}
                    {`${formatAndMultiplyValueAssetItem(
                      getMarginValue(
                        value,
                        margin,
                        askPrice,
                        bidPrice,
                        limitValue,
                        stopLossValue
                      ), instrumentType
                    )} (${margin * 100}%)`}
                  </MDTypography>
                </Grid>
              )}
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingX: 3,
                  paddingTop: 2,
                }}
              >
                <MDTypography sx={{fontSize:'17px'}}>Enter Quantity</MDTypography>
                <MDBox sx={{ display: "flex", flexDirection: "column" }}>
                  <MDInput
                    id="quantity"
                    label="Quantity"
                    variant="outlined"
                    size="small"
                    type="number"
                    value={quantity}
                    // error={quantityError}
                    onKeyDown={handleKeyPress}
                    onChange={handleQuantityChange}
                    sx={{
                      fontSize: "20px",
                      width: "170px",
                    }}
                  />
                  <FormHelperText>
                    {quantityError && (
                      <MDTypography
                        sx={{ fontSize: "11px", color: "red", fontWeight: 510 }}
                      >
                        {(!quantity ||
                          isNaN(quantity) ||
                          parseFloat(quantity) <= 0 ||
                          (instrumentType !== "CRYPTO" &&
                            !Number.isInteger(parseFloat(quantity)))) &&
                          "Enter valid quantity."}
                      </MDTypography>
                    )}
                  </FormHelperText>
                </MDBox>
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingX: 3,
                  paddingTop: 2,
                }}
              >
                <MDTypography sx={{fontSize:'17px'}}>Brokerage</MDTypography>
                <MDTypography sx={{fontSize:'17px'}}>
                  {formatAndMultiplyValueAssetItem(Brokerage, instrumentType)}
                </MDTypography>
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingX: 3,
                  paddingTop: 2,
                }}
              >
                <MDTypography sx={{fontSize:'17px'}}>Total</MDTypography>
                <MDTypography sx={{fontSize:'17px'}}>
                  {formatAndMultiplyValueAssetItem(total, instrumentType)}
                </MDTypography>
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Grid sx={{ display: "flex", padding: "20px" }}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={handleCloseModal}
                  >
                    Cancel
                  </MDButton>
                </Grid>
                <Grid
                  sx={{
                    display: "flex",
                    padding: "20px",
                    justifyContent: "flex-end",
                  }}
                >
                  {sellType ? (
                    <MDButton
                      color="error"
                      onClick={() => performTransaction("sell")}
                      disabled={isDisable || disableTrade()}
                    >
                      {value === "market" ? "Sell Now" : "Order Sell"}
                    </MDButton>
                  ) : (
                    <MDButton
                      color="success"
                      disabled={isDisable || disableTrade()}
                      onClick={() => performTransaction("buy")}
                    >
                      {value === "market" ? "Buy Now" : "Order Buy"}
                    </MDButton>
                  )}
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Modal>
      </Grid>
      <ToastContainer />
    </Grid>
  );
}

export default buyandsell;
