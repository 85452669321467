import React, { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import {
  Grid,
  useTheme as T,
  useMediaQuery,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { MoreVertRounded } from "@mui/icons-material";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { DataGrid } from "@mui/x-data-grid";
import { getUserOrders } from "../../services/portifolioapis";
import { useMaterialUIController } from "context";
import colors from "assets/theme/base/colors";
import { deleteOrder } from "services/transactionApi";
import { ToastContainer} from "react-toastify";
import { useNavigate } from "react-router-dom";
import { formatDateTime } from "utils/formatDateTime";
import { formatAndMultiplyValueAssetItem } from "utils/formatAndMultiplyValue";
import showToast from "utils/toastUtils";

function Orders() {
  const [selectedTab, setSelectedTab] = useState("Open");
  const [openOrders, setOpenOrders] = useState([]);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [anchorEl, setAnchorEl] = useState({});
  const [loading, setLoading] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;
  const { white, black } = colors;
  const t = T();
  const navigate = useNavigate();
  const isMedium = useMediaQuery(t.breakpoints.down("xl"));

  const fetchUserOrders = async () => {
    setLoading(true);
    try {
      const response = await getUserOrders();
      const open = response.orders.filter(
        (order) => order.status === "PENDING"
      );
      const pending = response.orders.filter(
        (order) => order.status !== "PENDING"
      );

      setOpenOrders(open);
      setPendingOrders(pending);
    } catch (error) {
      console.error("Error fetching orders: ", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedTab) {
      fetchUserOrders();
    }
  }, []);

  const showOpenOrderDetails = () => {
    const handleMenuOpen = (event, row) => {
      setAnchorEl({ ...anchorEl, [row.id]: event.currentTarget });
    };

    const handleCloseMenu = (rowId) => {
      setAnchorEl((prevAnchorEl) => ({
        ...prevAnchorEl,
        [rowId]: null,
      }));
    };

    const handleDelete = async (row) => {
      try {
        const data = {
          exchange: row.exchange,
          orderId: row.id,
        };
        const response = await deleteOrder(data);
        if (response.status === "success") {
          showToast("order Removed SuccessFull", "success");
        }
        setTimeout(() => {
          fetchUserOrders();
        }, 3000);
      } catch (error) {
        console.error("Error Removing Order");
      }
    };

    const GridHeader = ({ text }) => (
      <MDTypography
        sx={{ fontWeight: 550, paddingLeft: "17px", fontSize: "18px" }}
      >
        {text}
      </MDTypography>
    );

    const columns = [
      {
        field: "tradingSymbol",
        headerName: <GridHeader text="Name" />,
        sortable: false,
        filterable: false,
        width: isMedium ? 230 : undefined,
        flex: isMedium ? undefined : 1.5,
        renderCell: (params) => (
          <Tooltip title={params.value} enterDelay={500}>
            <MDTypography
              sx={{
                paddingLeft: "20px",
                overflow: "hidden",
                paddingTop: "5px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                fontSize: "18px",
              }}
            >
              {params.value}
            </MDTypography>
          </Tooltip>
        ),
      },
      {
        field: "buyType",
        headerName: <GridHeader text="Type" />,
        sortable: false,
        filterable: false,
        width: isMedium ? 170 : undefined,
        flex: isMedium ? undefined : 1.1,
        renderCell: (params) => (
          <MDTypography
            sx={{ paddingLeft: "20px", paddingTop: "5px", fontSize: "18px" }}
            color={params.value ? "success" : "error"}
          >
            {`${params.value ? "BUY" : "SELL"} - ${params.row.orderType}`}
          </MDTypography>
        ),
      },
      {
        field: "status",
        headerName: <GridHeader text="Status" />,
        sortable: false,
        filterable: false,
        width: isMedium ? 150 : undefined,
        flex: isMedium ? undefined : 1,
        renderCell: (params) => (
          <MDTypography
            sx={{ paddingLeft: "20px", paddingTop: "5px", fontSize: "18px" }}
          >
            {params.value}
          </MDTypography>
        ),
      },
      {
        field: "quantity",
        headerName: <GridHeader text="Qty" />,
        sortable: false,
        filterable: false,
        width: isMedium ? 80 : undefined,
        flex: isMedium ? undefined : 0.6,
        renderCell: (params) => (
          <MDTypography
            sx={{ paddingLeft: "20px", paddingTop: "5px", fontSize: "18px" }}
            color={params.value ? "success" : "error"}
          >
            {params.value}
          </MDTypography>
        ),
      },
      {
        field: "Price",
        headerName: <GridHeader text="Price" />,
        sortable: false,
        filterable: false,
        width: isMedium ? 130 : undefined,
        flex: isMedium ? undefined : 0.9,
        renderCell: (params) => (
          <MDTypography
            sx={{ paddingLeft: "20px", paddingTop: "5px", fontSize: "18px" }}
            color={params.value ? "success" : "error"}
          >
            {formatAndMultiplyValueAssetItem(params.value, params.row.exchange)}
          </MDTypography>
        ),
      },
      {
        field: "executedDate",
        headerName: <GridHeader text="Date" />,
        sortable: false,
        filterable: false,
        width: isMedium ? 230 : undefined,
        flex: isMedium ? undefined : 1.5,
        renderCell: (params) => (
          <MDTypography
            sx={{ paddingLeft: "20px", paddingTop: "5px", fontSize: "18px" }}
          >
            {formatDateTime(params.value)}
          </MDTypography>
        ),
      },
      {
        field: "actions",
        headerName: <GridHeader text="Modify" />,
        width: isMedium ? 130 : undefined,
        flex: isMedium ? undefined : 1,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <MDBox
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "50%",
            }}
          >
            <IconButton
              aria-label="actions"
              aria-controls={`menu-${params.row.rowId}`}
              aria-haspopup="true"
              onClick={(event) => handleMenuOpen(event, params.row)}
              sx={{
                marginRight: "10px",
              }}
            >
              <MoreVertRounded
                style={{
                  cursor: "pointer",
                  color: darkMode ? white.main : black.main,
                }}
              />
            </IconButton>
            <Menu
              id={`menu-${params.row.id}`}
              anchorEl={anchorEl[params.row.id]}
              open={Boolean(anchorEl[params.row.id])}
              onClose={() => handleCloseMenu(params.row.id)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              sx={{
                "& .MuiPaper-root": {
                  width: "130px",
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  navigate("/charts", {
                    state: {
                      instrumentId: params.row?.instrumentId,
                      instrumentType: params.row?.exchange,
                      TradingSymbol: params.row?.tradingSymbol,
                      modifyOrderType: params.row?.orderType,
                      modifyType: params.row?.buyType,
                      modifyPrice: params.row?.Price,
                      modifyQuantity: params.row?.quantity,
                      orderId: params.row?.id,
                      selectedOption: selectedTab,
                    },
                  });
                }}
              >
                Modify
              </MenuItem>
              <MenuItem onClick={() => handleDelete(params.row)}>
                Delete
              </MenuItem>
            </Menu>
          </MDBox>
        ),
      },
    ];

    const rows = openOrders.map((order, index) => ({
      id: order.ID,
      tradingSymbol: order.tradingSymbol,
      exchange: order.exchange,
      buyType: order.buy,
      orderType: order.orderType,
      quantity: order.quantity,
      Price: order.triggerPrice,
      Date: order.createdAt,
      status: order.status,
      instrumentId: order.instrumentId,
      executedDate: order.createdAt,
    }));

    function customNoRows() {
      return (
        <MDBox
          height={"100%"}
          width={"100%"}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MDTypography sx={{ fontSize: isMedium ? "15px" : "20px" }}>
            You currently have no Open Orders!
          </MDTypography>
        </MDBox>
      );
    }

    return (
      <Grid lg={12} xl={12} md={12} sm={12} xs={12}>
        <DataGrid
          disableColumnMenu
          rows={rows}
          columns={columns}
          hideFooter={true}
          rowHeight={45}
          loading={loading}
          slots={{
            noRowsOverlay: customNoRows,
          }}
          disableSelectionOnClick
          disableRowSelectionOnClick
          hideFooterPagination
          disableColumnResize
          autoHeight
          classes={{ display: "flex", cursor: "pointer" }}
          sx={{
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-cell:focus-within": {
              outline: "none",
            },
            "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within": {
              outline: "none",
            },
          }}
        />
      </Grid>
    );
  };

  const showRemainingOrderDetails = () => {
    const GridHeader = ({ text }) => (
      <MDTypography
        sx={{ fontWeight: 550, paddingLeft: "17px", fontSize: "18px" }}
      >
        {text}
      </MDTypography>
    );

    const handleMenuOpen = (event, row) => {
      setAnchorEl({ ...anchorEl, [row.id]: event.currentTarget });
    };

    const handleCloseMenu = (rowId) => {
      setAnchorEl((prevAnchorEl) => ({
        ...prevAnchorEl,
        [rowId]: null,
      }));
    };

    const columns = [
      {
        field: "tradingSymbol",
        headerName: <GridHeader text="Name" />,
        sortable: false,
        filterable: false,
        width: isMedium ? 230 : undefined,
        flex: isMedium ? undefined : 1.5,
        renderCell: (params) => (
          <Grid
            sx={{
              paddingLeft: "20px",
            }}
          >
            <Tooltip title={params.value} enterDelay={500}>
              <MDTypography
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  paddingTop: "5px",
                  fontSize: "18px",
                }}
              >
                {params.value}
              </MDTypography>
            </Tooltip>
          </Grid>
        ),
      },
      {
        field: "buyType",
        headerName: <GridHeader text="Type" />,
        sortable: false,
        filterable: false,
        width: isMedium ? 170 : undefined,
        flex: isMedium ? undefined : 1.5,
        renderCell: (params) => (
          <MDTypography
            sx={{ paddingLeft: "20px", paddingTop: "5px", fontSize: "18px" }}
            color={params.value ? "success" : "error"}
          >{`${params.value ? "BUY" : "SELL"} - ${
            params.row.orderType
          }`}</MDTypography>
        ),
      },
      {
        field: "status",
        headerName: <GridHeader text="Status" />,
        sortable: false,
        filterable: false,
        width: isMedium ? 150 : undefined,
        flex: isMedium ? undefined : 1.2,
        renderCell: (params) => (
          <MDTypography
            sx={{ paddingLeft: "20px", paddingTop: "5px", fontSize: "18px" }}
            color={params.value === "SUCCESS" ? "success" : "error"}
          >
            {params.value}
          </MDTypography>
        ),
      },
      {
        field: "quantity",
        headerName: <GridHeader text="Qty" />,
        sortable: false,
        filterable: false,
        width: isMedium ? 80 : undefined,
        flex: isMedium ? undefined : 0.8,
        renderCell: (params) => (
          <MDTypography
            sx={{ paddingLeft: "20px", paddingTop: "5px", fontSize: "18px" }}
            color={params.value ? "success" : "error"}
          >
            {params.value}
          </MDTypography>
        ),
      },
      {
        field: "Price",
        headerName: <GridHeader text="Price" />,
        sortable: false,
        filterable: false,
        width: isMedium ? 150 : undefined,
        flex: isMedium ? undefined : 1,
        renderCell: (params) => (
          <MDTypography
            sx={{ paddingLeft: "20px", paddingTop: "5px", fontSize: "18px" }}
            color={params.value ? "success" : "error"}
          >
            {formatAndMultiplyValueAssetItem(params.value, params.row.exchange)}
          </MDTypography>
        ),
      },
      {
        field: "executedDate",
        headerName: <GridHeader text="Date" />,
        sortable: false,
        filterable: false,
        width: isMedium ? 230 : undefined,
        flex: isMedium ? undefined : 1.5,
        renderCell: (params) => (
          <MDTypography
            sx={{ paddingLeft: "20px", paddingTop: "5px", fontSize: "18px" }}
          >
            {formatDateTime(params.value)}
          </MDTypography>
        ),
      },
      {
        field: "actions",
        headerName: <GridHeader text="Modify" />,
        width: isMedium ? 130 : undefined,
        flex: isMedium ? undefined : 1,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          const checkStatus = params.row.status === "CANCELLED";
          return (
            <>
              {checkStatus && (
                <MDBox
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "50%",
                  }}
                >
                  <IconButton
                    aria-label="actions"
                    aria-controls={`menu-${params.row.rowId}`}
                    aria-haspopup="true"
                    onClick={(event) => handleMenuOpen(event, params.row)}
                    sx={{
                      marginRight: "10px",
                    }}
                  >
                    <MoreVertRounded
                      style={{
                        cursor: "pointer",
                        color: darkMode ? white.main : black.main,
                      }}
                    />
                  </IconButton>
                  <Menu
                    id={`menu-${params.row.id}`}
                    anchorEl={anchorEl[params.row.id]}
                    open={Boolean(anchorEl[params.row.id])}
                    onClose={() => handleCloseMenu(params.row.id)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    sx={{
                      "& .MuiPaper-root": {
                        width: "130px",
                      },
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        navigate("/charts", {
                          state: {
                            instrumentId: params.row?.instrumentId,
                            instrumentType: params.row?.exchange,
                            TradingSymbol: params.row?.tradingSymbol,
                            modifyOrderType: params.row?.orderType,
                            modifyType: params.row?.buyType,
                            modifyPrice: params.row?.Price,
                            modifyQuantity: params.row?.quantity,
                            orderId: params.row?.id,
                            selectedOption: selectedTab,
                          },
                        });
                      }}
                    >
                      Modify
                    </MenuItem>
                    {/* <MenuItem onClick={() => handleDelete(params.row)}>Delete</MenuItem> */}
                  </Menu>
                </MDBox>
              )}
            </>
          );
        },
      },
    ];

    const rows = pendingOrders.map((order, index) => ({
      id: order.ID,
      tradingSymbol: order.tradingSymbol,
      exchange: order.exchange,
      buyType: order.buy,
      orderType: order.orderType,
      quantity: order.quantity,
      Price: order.triggerPrice,
      Date: order.createdAt,
      status: order.status,
      instrumentId: order.instrumentId,
      executedDate: order.executedAt,
    }));

    function customNoRows() {
      return (
        <MDBox
          height={"100%"}
          width={"100%"}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MDTypography sx={{ fontSize: isMedium ? "15px" : "20px" }}>
            No Orders
          </MDTypography>
        </MDBox>
      );
    }

    return (
      <Grid lg={12} xl={12} md={12} sm={12} xs={12}>
        <DataGrid
          disableColumnMenu
          rows={rows}
          columns={columns}
          hideFooter={true}
          rowHeight={45}
          loading={loading}
          slots={{
            noRowsOverlay: customNoRows,
          }}
          disableSelectionOnClick
          disableRowSelectionOnClick
          hideFooterPagination
          disableColumnResize
          autoHeight
          classes={{ display: "flex", cursor: "pointer" }}
          sx={{
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-cell:focus-within": {
              outline: "none",
            },
            "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within": {
              outline: "none", 
            },
          }}
        />
      </Grid>
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid lg={12} md={12} sm={12} xs={12} xl={12}>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "Wrap",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Grid
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
              alignItems: "center",
            }}
          >
            <MDBox
              sx={{
                cursor: "pointer",
                paddingX: "10px",
                paddingY: "3px",
                borderBottom:
                  selectedTab === "Open" ? "2.8px solid #4169E1" : "none",
              }}
              data-testid="open-orders-button"
              onClick={() => setSelectedTab("Open")}
            >
              <MDTypography
                sx={{ fontWeight: selectedTab === "Open" ? 500 : "none" }}
              >
                Open
              </MDTypography>
            </MDBox>
            <MDBox
              sx={{
                cursor: "pointer",
                paddingX: "10px",
                paddingY: "3px",
                borderBottom:
                  selectedTab === "Executed" ? "2.8px solid #4169E1" : "none",
              }}
              onClick={() => setSelectedTab("Executed")}
              data-testid="pending-orders-button"
            >
              <MDTypography
                sx={{ fontWeight: selectedTab === "Executed" ? 500 : "none" }}
              >
                Executed
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
        <Grid
          lg={12}
          md={12}
          sm={12}
          xs={12}
          xl={12}
          sx={{ marginTop: "20px" }}
          container
        >
          {selectedTab === "Open"
            ? showOpenOrderDetails()
            : showRemainingOrderDetails()}
        </Grid>
        <ToastContainer />
      </Grid>
    </DashboardLayout>
  );
}

export default Orders;
