import React, { useState} from "react";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Card, Divider,Grid } from "@mui/material";
import Modal from "@mui/material/Modal";
import MDInput from "components/MDInput";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { jwtDecode } from "jwt-decode";
import { Support} from "../../../services/profileapis";
import showToast from "utils/toastUtils";

function OpenTickets({ fetchTicketDatas }) {
  const [openModel, setOpenModel] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setdescription] = useState("");

  const handleOpenModel = () => {
    setOpenModel(true);
  };

  const handleCloseModel = () => {
    setOpenModel(false);
    setTitle("");
    setdescription("");
  };

  const handupdate = () => {
    fetchTicketDatas();
  };

  const openTicket = async () => {
    if (!title || !description) {
     showToast("Please Provide Title And Description", "error");
     return
    }

    if (title.startsWith(" ") || description.startsWith(" ") || title.endsWith(" ") || description.endsWith(" ")) {
     showToast("Contains space", "error");
      return;
    }

    try {
      const token = sessionStorage.getItem("token");
      const decode = jwtDecode(token);
      const userid = decode.UserID;

      const data = {
        user_id: userid,
        subject: title,
        description: description,
        status: "Pending",
      };

      const response = await Support(data, token);

      if (response) {
        showToast("Ticket Raised Successfully", "success");
        handupdate();
        setTitle("");
        setdescription("");
        setTimeout(() => {
          handleCloseModel();
        }, 1000);
      }
    } catch (error) {
      console.error("Error in openTicket:", error);
      showToast("An error occurred while processing your request.","error");
    }
  };

  return (
    <Grid lg={12}>
      <MDButton sx={{ padding: "15px" }} color="info" variant="gradient"  onClick={handleOpenModel} data-testid="create-ticket-button">
        New Ticket
      </MDButton>
      <Modal
        role="create-ticket-modal"
        open={openModel}
        onClose={handleCloseModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MDBox
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Card
            sx={{
              borderRadius: "10px",
              paddingX: "30px",
              paddingY: "10px",
            }}
          >
            <Grid lg={12}>
              <form>
                <MDTypography sx={{fontSize:'20px', textAlign: "center"}}>
                 Raise a Support Ticket
                </MDTypography>
                <Divider/>
                <MDBox sx={{ marginY: "10px" }}>
                  <MDTypography sx={{fontWeight: 500, fontSize:'18px'}}>Title</MDTypography>
                  <MDInput
                    placeholder="Enter Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    sx={{ width: "300px" }}
                  />
                </MDBox>
                <MDBox sx={{ marginY: "10px" }}>
                  <MDTypography sx={{fontWeight: 500, fontSize:'18px'}}>Description</MDTypography>
                  <MDInput
                    placeholder="Enter Description"
                    value={description}
                    onChange={(e) => setdescription(e.target.value)}
                    multiline
                    rows={5}
                    sx={{
                      width: "300px",
                      fontSize: "25px",
                      borderRadius: "6px",
                    }}
                    required
                  />
                </MDBox>
                <MDBox
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginY: "20px",
                  }}
                >
                  <MDButton color="error" onClick={handleCloseModel} data-testid="cancel-create-button">
                    cancel
                  </MDButton>
                  <MDButton color="success" onClick={() => openTicket()} data-testid="submit-create-button">
                    submit
                  </MDButton>
                </MDBox>
              </form>
            </Grid>
          </Card>
        </MDBox>
      </Modal>
      <ToastContainer />
    </Grid>
  );
}
export default OpenTickets;
