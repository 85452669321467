import React from "react";
import { Grid , Box} from "@mui/material";
import MDTypography from "components/MDTypography";
import PageLayout from "examples/LayoutContainers/PageLayout";
import { tradingNotes, termsConditions, contactInfo} from "utils/termsConditions";

function TermsAndConditions() {
  return (
    <PageLayout>
      <Grid
        lg={12}
        md={12}
        sm={12}
        xs={12}
        xl={12}
        padding={"20px"}
        height={"100vh"}
      >
        <MDTypography variant="h3">Terms And Conditions</MDTypography>
        <Grid lg={12} md={12} sm={12} xs={12} xl={12} marginTop={"20px"}>
          <MDTypography variant="h5"  sx={{paddingBottom:'1px'}}>Trading Notes</MDTypography>
          {tradingNotes.map((note) => (
              <MDTypography key={note.id} sx={{fontSize:'15px', paddingY:'1.2px'}}>
                {note.label}
              </MDTypography>
          ))}
        </Grid>
        <Grid lg={12} md={12} sm={12} xs={12} xl={12} marginTop={"20px"}>
          <MDTypography variant="h5" sx={{paddingBottom:'1px'}}>Terms and Conditions </MDTypography>
          {termsConditions.map((note) => (
              <MDTypography key={note.id} sx={{fontSize:'15px', paddingY:'1.2px'}}>
                {note.label}
              </MDTypography>
          ))}
        </Grid>
        <Grid lg={12} md={12} sm={12} xs={12} xl={12} marginTop={"20px"}>
          <MDTypography variant="h5"  sx={{paddingBottom:'1px'}}>Contact </MDTypography>
          {contactInfo.map((note) => (
              <MDTypography key={note.id} sx={{fontSize:'15px', paddingY:'1.2px'}}>
                {note.label}
              </MDTypography>
          ))}
        </Grid>
      </Grid>
    </PageLayout>
  );
}
export default TermsAndConditions;
