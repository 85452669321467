import React, { useState, useEffect, useContext } from "react";
import { Grid, Modal, Card, Button, InputAdornment } from "@mui/material";
import { updatepassword } from "../../../../services/profileapis";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { ToastContainer } from "react-toastify";
import showToast from "utils/toastUtils";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import BasicLayout from "layouts/authentication/components/BasicLayout";

const Cover = () => {
  const token = sessionStorage.getItem("token");
  const [currentPassword, setcurrentPassword] = useState("");
  const [newPassword, setnewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
  const [apiError, setApiError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const navigate = useNavigate();

  const handleButtonPress = () => {
    setcurrentPassword("");
    setConfirmPassword("");
    setnewPassword("");
    setConfirmPasswordError("");
    setApiError("");
    setShowPassword(false);
    setPasswordShow(false);
    setConfirmPasswordShow(false);
    setTimeout(() => {
      navigate("/login");
    }, 1000);
  };

  const updatepassword1 = () => {
    setApiError("");
    setConfirmPasswordError("");

    const currentPwd = currentPassword.trim();
    const newPwd = newPassword.trim();
    const confirmPwd = confirmPassword.trim();

    if (!currentPwd || !newPwd || !confirmPwd) {
      showToast("Please enter all fields.", "error");
      return;
    }

    if (newPwd !== confirmPwd) {
      setConfirmPasswordError(
        "New Password and Confirm Password do not match."
      );
      return;
    }

    if (currentPwd == newPwd) {
      setApiError("Current password and new password is same.");
      return;
    }

    updatepassword(currentPwd, newPwd, confirmPwd, token)
      .then((response) => {
        if (response.success) {
          showToast("Update Password Successfully", "success");
          sessionStorage.removeItem(token);
          setTimeout(() => {
            navigate("/login");
          }, 1000);
          setcurrentPassword("");
          setConfirmPasswordError("");
          setShowPassword(false);
          setPasswordShow(false);
          setConfirmPasswordShow(false);
          setnewPassword("");
          setConfirmPassword("");
          setApiError("");
        } else {
          setApiError(response.error || "Current Password is Incorrect");
          setcurrentPassword("");
          setnewPassword("");
          setConfirmPassword("");
        }
      })
      .catch((error) => {
        showToast(
          error.message || "An error occurred while updating password",
          "error"
        );
      });
  };

  function handleTogglePasswordVisibility() {
    setShowPassword(!showPassword);
  }

  function handlenewPasswords() {
    setPasswordShow(!passwordShow);
  }
  function handleChangeconfirmPassword() {
    setConfirmPasswordShow(!confirmPasswordShow);
  }

  return (
    <BasicLayout>
      <Card
        sx={{
          borderRadius: "10px",
          paddingX: "30px",
          paddingY: "6px",
          width: "100%",
        }}
      >
        <MDTypography
          style={{ fontWeight: "bold", fontSize: "30px" }}
          color="info"
        >
          Update Password
        </MDTypography>
        <Grid style={{ textAlign: "center" }}>
          <Grid
            mt={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <MDBox mb={1.5} sx={{ display: "flex", justifyContent: "center" }}>
              <MDInput
                type={showPassword ? "text" : "password"}
                label="Enter Current Password"
                fullWidth
                value={currentPassword}
                onChange={(e) => {
                  setcurrentPassword(e.target.value);
                  setApiError("");
                }}
                InputProps={{
                  endAdornment: (
                    <Button
                    data-testid="show-current-button"
                      onClick={handleTogglePasswordVisibility}
                      sx={{ padding: "0" }}
                    >
                      {showPassword ? (
                        <Visibility
                          sx={{ color: "#9CAEB8" }}
                          style={{ fontSize: "2rem" }}
                        />
                      ) : (
                        <VisibilityOff
                          sx={{ color: "#9CAEB8" }}
                          style={{ fontSize: "2rem" }}
                        />
                      )}
                    </Button>
                  ),
                }}
              />
            </MDBox>
            {apiError && (
              <MDTypography
                sx={{ fontSize: "12px", fontWeight: "500" }}
                mb={1}
                color="error"
                data-testid="api-error"
              >
                {apiError}
              </MDTypography>
            )}
            <MDBox mb={1.5} sx={{ display: "flex", justifyContent: "center" }}>
              <MDInput
                type={passwordShow ? "text" : "password"}
                label="Enter New Password"
                fullWidth
                value={newPassword}
                onChange={(e) => {
                  setnewPassword(e.target.value);
                  setConfirmPasswordError("");
                }}
                sx={{
                  "& label.Mui-focused": {
                    color:
                      currentPassword && newPassword
                        ? currentPassword.trim() === newPassword.trim()
                          ? "red"
                          : "#0BDA51"
                        : "defaultColor",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor:
                        currentPassword && newPassword
                          ? currentPassword.trim() === newPassword.trim()
                            ? "red"
                            : "#0BDA51"
                          : "defaultColor",
                    },
                    "&:hover fieldset": {
                      borderColor: "white",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor:
                        currentPassword && newPassword
                          ? currentPassword.trim() === newPassword.trim()
                            ? "red"
                            : "#0BDA51"
                          : "defaultColor",
                    },
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <Button onClick={handlenewPasswords} sx={{ padding: "0" }} data-testid="show-new-button">
                      {passwordShow ? (
                        <Visibility
                          sx={{ color: "#9CAEB8" }}
                          style={{ fontSize: "2rem" }}
                        />
                      ) : (
                        <VisibilityOff
                          sx={{ color: "#9CAEB8" }}
                          style={{ fontSize: "2rem" }}
                        />
                      )}
                    </Button>
                  ),
                }}
              />
            </MDBox>
            <MDBox mb={1.5} sx={{ display: "flex", justifyContent: "center" }}>
              <MDInput
                type={confirmPasswordShow ? "text" : "password"}
                label="Enter Confirm Password"
                fullWidth
                value={confirmPassword}
                //onBlur={() => validatePassword(password)}
                sx={{
                  "& label.Mui-focused": {
                    color:
                      newPassword && confirmPassword
                        ? newPassword.trim() !== confirmPassword.trim()
                          ? "red"
                          : "#0BDA51"
                        : "defaultColor",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor:
                        newPassword && confirmPassword
                          ? newPassword.trim() !== confirmPassword.trim()
                            ? "red"
                            : "#0BDA51"
                          : "defaultColor",
                    },
                    "&:hover fieldset": {
                      borderColor: "white",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor:
                        newPassword && confirmPassword
                          ? newPassword.trim() !== confirmPassword.trim()
                            ? "red"
                            : "#0BDA51"
                          : "defaultColor",
                    },
                  },
                }}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  // validatePassword(e.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <Button
                    data-testid="show-confirm-button"
                      onClick={handleChangeconfirmPassword}
                      sx={{ padding: "0" }}
                    >
                      {confirmPasswordShow ? (
                        <Visibility
                          sx={{ color: "#9CAEB8" }}
                          style={{ fontSize: "2rem" }}
                        />
                      ) : (
                        <VisibilityOff
                          sx={{ color: "#9CAEB8" }}
                          style={{ fontSize: "2rem" }}
                        />
                      )}
                    </Button>
                  ),
                }}
              />
            </MDBox>
            {confirmPasswordError && (
              <MDTypography
                sx={{ fontSize: "12px", fontWeight: "500" }}
                mb={1}
                variant="body2"
                color="error"
                data-testid="confirm-password-error"
              >
                {confirmPasswordError}
              </MDTypography>
            )}
          </Grid>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-around",
              flexDirection: "row",
            }}
          >
            <MDBox mt={4} mb={1}>
              <MDButton
                data-testid="cancel-button"
                variant="gradient"
                color="error"
                onClick={handleButtonPress}
              >
                Cancel
              </MDButton>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
              data-testid="password-update-button"
                variant="gradient"
                color="success"
                onClick={() => updatepassword1()}
                disabled={newPassword.includes(" ")}
              >
                Update
              </MDButton>
            </MDBox>
          </Grid>
          {newPassword.includes(" ") && (
            <MDTypography color="error" sx={{ fontSize: "12px" }}>
              new password must not contain space
            </MDTypography>
          )}
        </Grid>
      </Card>
      <ToastContainer />
    </BasicLayout>
  );
};

export default Cover;
