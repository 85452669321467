import React, { useState, useEffect } from "react";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Grid, Card, useMediaQuery, useTheme as T } from "@mui/material";
import OpenTickets from "./openTickets";
import { Supportlist } from "services/profileapis";
import { jwtDecode } from "jwt-decode";
import { DataGrid } from "@mui/x-data-grid";
import Modal from "@mui/material/Modal";

function Tickets() {
  const t = T();
  const isMedium = useMediaQuery(t.breakpoints.down("md"));
  const isSmall = useMediaQuery(t.breakpoints.down("lg"));
  const [ticketData, setTicketData] = useState([]);
  const [subject, setSubject] = useState("");
  const [brief, setBrief] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const handleClick = (params) => {
    const reason = params.row.name;
    const text = params.row.description;
    setSubject(reason);
    setBrief(text);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    fetchTicketDatas();
  }, []);

  const fetchTicketDatas = async () => {
    const token = sessionStorage.getItem("token");
    const decode = jwtDecode(token);
    const userid = decode.UserID;

    try {
      const response = await Supportlist(userid);
      const formattedResponseArray = response.map((item) => {
        const createdAtDate = new Date(item.created_at);
        const formattedCreatedAt = `${createdAtDate.getDate()}/${
          createdAtDate.getMonth() + 1
        }/${createdAtDate.getFullYear()}`;

        return {
          id: item.id,
          name: item.subject,
          status: item.status,
          description: item.description,
          createdAt: formattedCreatedAt,
        };
      });

      const sortedDataArray = formattedResponseArray.sort((a, b) => b.id - a.id);

      setTicketData(sortedDataArray);
    } catch (error) {
      console.error("Error fetching tickets:", error);
    }
  };

  

  const GridHeader = ({ text }) => (
    <MDTypography sx={{ fontWeight: 550, paddingLeft: "18px", fontSize:'18px' }}>{text}</MDTypography>
  );

  const columns = [
    {
      field: "id",
      headerName: <GridHeader text="ID" />,
      sortable: false,
      filterable: false,
      width: isSmall ? 200 : undefined, 
      flex: isSmall ? undefined : 1.5,
      renderCell: (params) => (
        <MDTypography sx={{ paddingLeft: "20px", paddingTop:'5px', fontSize:'18px'  }} data-testid="row-id">{params.value}</MDTypography>
      ),
    },
    {
      field: "name",
      headerName: <GridHeader text="Name" />,
      sortable: false,
      filterable: false,
      width: isSmall ? 200 : undefined, 
      flex: isSmall ? undefined : 1.5,
      renderCell: (params) => (
        <MDTypography sx={{ paddingLeft: "20px", paddingTop:'5px', fontSize:'18px' }}>{params.value}</MDTypography>
      ),
    },
    {
      field: "createdAt",
      headerName: <GridHeader text="Date" />,
      sortable: false,
      filterable: false,
      width: isSmall ? 200 : undefined, 
      flex: isSmall ? undefined : 1.5,
      renderCell: (params) => (
        <MDTypography sx={{ paddingLeft: "20px", paddingTop:'5px', fontSize:'18px' }}>{params.value}</MDTypography>
      ),
    },
    {
      field: "status",
      headerName: <GridHeader text="status" />,
      sortable: false,
      filterable: false,
      width: isSmall ? 200 : undefined, 
      flex: isSmall ? undefined : 1.5,
      renderCell: (params) => (
        <MDTypography
          color={params.value !== "Pending" ? "success" : "error"}
          sx={{
            paddingLeft: "20px",
            paddingTop:'5px', 
            fontSize:'18px',
            fontWeight: 500,
          }}
        >
          {params.value}
        </MDTypography>
      ),
    },
  ];

  function customNoRows() {
    return (
      <MDBox
        height={"100%"}
        width={"100%"}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <MDTypography sx={{ fontSize: isMedium ? "15px" : "20px", textAlign: "center" }}>
          Looks like everything’s running smoothly! No support tickets to show.
        </MDTypography>
      </MDBox>
    );
  }

  return (
    <Grid container lg={12}>
      <OpenTickets fetchTicketDatas={fetchTicketDatas} />
      <Grid
        lg={11}
        md={11}
        sm={12}
        xs={12}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        marginTop={"20px"}
        style={{ cursor: "pointer" }}
      >
        <DataGrid
          disableColumnMenu
          columns={columns}
          rows={ticketData}
          rowHeight={45}
          isSorted={true}
          slots={{
            noRowsOverlay: customNoRows,
          }}
          getRowId={(data) => data.id}
          hideFooter={true}
          autoHeight
          disableRowSelector={false}
          disableColumnResize
          pageSize={10}
          onRowClick={handleClick}
          sx={{
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-cell:focus-within": {
              outline: "none", 
            },
            "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within": {
              outline: "none",
            },
          }}
          hideFooterPagination={true}
        />
        <Modal
          role="details-modal"
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MDBox
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card
              sx={{
                borderRadius: "20px",
                paddingX: "15px",
                paddingY: "15px",
                width: "300px",
              }}
            >
              <Grid borderRadius={"10px"}>
                <MDTypography sx={{ paddingY: "10px", fontSize: "18px", fontWeight: 500 }}>
                  subject<span style={{ color: "green" }}> :</span> {subject}
                </MDTypography>
                <MDTypography sx={{ paddingY: "10px", fontSize: "18px", fontWeight: 500 }}>
                  Description<span style={{ color: "green" }}> :</span> {brief}
                </MDTypography>
                <MDButton
                  onClick={handleCloseModal}
                  data-testid="close-details-modal"
                  sx={{ paddingX: "20px", paddingY: "10px", marginTop: "20px" }}
                  color="success"
                  variant="gradient"
                >
                  close
                </MDButton>
              </Grid>
            </Card>
          </MDBox>
        </Modal>
      </Grid>
    </Grid>
  );
}
export default Tickets;
