// @mui material components
import React, { useState, useEffect, useRef, useMemo } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import {
  Card,
  Grid,
  Skeleton,
  Autocomplete,
  TextField,
  useMediaQuery,
  useTheme as T,
} from "@mui/material";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useMaterialUIController } from "context";
import colors from "assets/theme/base/colors";
// Dashboard components
import NDAQ from "../../assets/images/icons/flags/NDAQ.png";
import NseIcon from "../../assets/images/icons/flags/NseIcon.png";
import commodity from "../../assets/images/icons/flags/commodity.png";
import Crypto from "../../assets/images/icons/flags/Crypto.png";
import CardItemsNasdaq from "../dashboard/components/CardItemsNasdaq";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { jwtDecode } from "jwt-decode";
import SearchBar from "./components/SearchBar";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import { getPortfolio, getEnabledTokens } from "../../services/portifolioapis";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import PortfolioCard from "./components/PortfolioCard";
import "chart.js/auto";
import Chart from "./components/TotalChart/PortfolioChart";
import ScrollText from "./components/scroller";
import useSocket from "../../Sockets/Socket";
import { usePortfolioWebSocket } from "../../Sockets/Socket";
import { formatAndMultiplyValueAssetItem } from "utils/formatAndMultiplyValue";

const options = [
  { id: "7", label: "7 days" },
  { id: "14", label: "14 days" },
  { id: "30", label: "This month" },
];

function Dashboard() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [nseData, SetnseData] = useState([]);
  const [rowCount, setRowCount] = useState();
  const [page, setpage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState();
  const [enabledTokens, setEnabledTokens] = useState([]);
  const [enabledmarkets, setEnabledMarkets] = useState([]);
  const [value, setValue] = useState();
  const [portfolioValues, setPortfolioValues] = useState({});
  const [chartData, setChartData] = useState({});
  const [totalPortfolioLoading, setTotalPortfolioLoading] = useState(false);
  const [days, setDays] = useState(options[0].id);
  const [loader, setLoader] = useState(true);
  const token = sessionStorage.getItem("token");
  const activeMarkets = sessionStorage.getItem("enabledmarkets");
  const storedCurrency = sessionStorage.getItem("currency");
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { white, black } = colors;
  const t = T();
  const isSmall = useMediaQuery(t.breakpoints.down("md"));
  const sorted = useMediaQuery(t.breakpoints.up("md"));

  //USEREF INTAITE VALUES
  const previousPrices = useRef({});
  const previousBidPrices = useRef({});
  const previousAskPrices = useRef({});
  const previousColors = useRef({
    askPriceColors: {},
    bidPriceColors: {},
  });

  const apiContext = [
    {
      id: "NSE",
      totalValue: chartData?.nse,
    },
    {
      id: "NASDAQ",
      totalValue: chartData?.nasdaq,
    },
    {
      id: "CRYPTO",
      totalValue: chartData?.crypto,
    },
    {
      id: "Commodity",
      totalValue: chartData?.commodity,
    },
    {
      id: "MCX",
      totalValue: chartData?.mcx,
    },
  ];

  const [marketContext, setMarketContext] = useState([
    {
      id: "MCX",
      label: "MCX",
      totalValue: 0,
      percentage: "0.00",
      Image: commodity,
    },
    {
      id: "NSE",
      label: "NSE",
      totalValue: 0,
      percentage: "0.00",
      Image: NseIcon,
    },
    {
      id: "CRYPTO",
      label: "CRYPTO",
      totalValue: 0,
      percentage: "0.00",
      Image: Crypto,
    },
    {
      id: "NASDAQ",
      label: "NASDAQ",
      totalValue: 0,
      percentage: "0.00",
      Image: NDAQ,
    },
    {
      id: "Commodity",
      label: "COMMODITY",
      totalValue: 0,
      Image: commodity,
    },
  ]);

  const [selectedButton, setSelectedButton] = useState("");
  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
    sessionStorage.setItem("selectedExchange", buttonName);
    SetnseData([]);
    fetchData(buttonName);
  };

  // USEEFECTS FOR API CALL
  useEffect(() => {
    const savedTab = sessionStorage.getItem("selectedExchange");
    if (savedTab) {
      setSelectedButton(savedTab);
    } else {
      setSelectedButton(enabledTokens[0]);
    }
  }, [selectedButton, enabledTokens]);

  const getGradientOnTheme = () => {
    if (darkMode) {
      return null;
    }
    return "linear-gradient(195deg, #cfd8dc, #cfd8dc)";
  };

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    // if (!token) return false;
    const decodedToken = jwtDecode(token);
    setId(decodedToken.UserID);
  }, []);

  useEffect(() => {
    if (
      token &&
      selectedButton &&
      page &&
      marketContext.length > 0 &&
      enabledTokens.length > 0
    ) {
      fetchData(selectedButton, page);
    }
  }, [token, selectedButton, page, days, enabledTokens]);

  useEffect(() => {
    if (token && id) {
      fetchTokens(id);
    }
  }, [id, token]);

  useEffect(() => {
    if (data) {
      setEnabledTokens(data.map((item) => item.exchange));
    }
  }, [data]);

  // STORE ENABLED MARKETS IN SESSION STORAGE
  useEffect(() => {
    if (enabledTokens) {
      sessionStorage.setItem("enabledmarkets", JSON.stringify(enabledTokens));
      const updatedMarkets = marketContext?.filter((market) =>
        enabledTokens.includes(market.id.toUpperCase())
      );
      setEnabledMarkets(updatedMarkets);
    }
  }, [enabledTokens]);

  // FETCH ENABLED MARKETS API CALL
  const fetchTokens = async (userid) => {
    setLoader(true);
    try {
      const responseData = await getEnabledTokens(userid, token);
      setData(responseData);
    } catch (err) {
      console.error(err);
    }
  };

  const enabledMarketContext = marketContext.filter((market) =>
    activeMarkets?.includes(market.id)
  );

  // FUNCTION TO RECEIVE DATA FROM SOCKET AND UPDATE STATE FOR LIVE PORTFOLIO DATA
  const handleDataReceived = (data) => {
    setTotalPortfolioLoading(true);
    const portfolioData = data?.find((data) => data?.type === "TOTAL");
    setPortfolioValues(portfolioData);
    setTotalPortfolioLoading(false);

    setValue(data);
    const updatedContext = marketContext.map((context) => {
      const matchingData = data?.find(
        (data) => data?.type.toLowerCase() === context?.id.toLowerCase()
      );

      if (matchingData) {
        return {
          ...context,
          totalValue: matchingData?.PNL,
          percentage: `${parseFloat(matchingData.PNL_PERCENTAGE).toFixed(2)}`,
        };
      }
      return context;
    });

    setMarketContext(updatedContext);
    setTotalPortfolioLoading(false);
  };

  const { connectSocket, closeSocket } = useSocket(
    `${process.env.REACT_APP_SOCKET_URL}/getUserBalances`,
    `${id}:${storedCurrency}`,
    handleDataReceived
  );

  // USEEFFECT FOR SOCKET CONNECTION FOR PORTFOLIO LIVE VALUES
  useEffect(() => {
    if (id && enabledTokens?.length > 0 && storedCurrency) {
      connectSocket();
    }
    return () => {
      closeSocket();
    };
  }, [id, storedCurrency, enabledTokens]);

  const {
    socketData,
    handelSocketOpen,
    handleSocketClose,
  } = usePortfolioWebSocket(
    `${process.env.REACT_APP_SOCKET_URL}/getUserPortfolio`
  );

  // USEEFFECT FOR SOCKET CONNECTION FOR PORTFOLIO DATA
  useEffect(() => {
    if (selectedButton && id && enabledTokens.length > 0) {
      handelSocketOpen(selectedButton, id);
    }
    return () => {
      handleSocketClose();
    };
  }, [selectedButton, id, handelSocketOpen, handleSocketClose, enabledTokens]);

  useEffect(() => {
    if (nseData?.length > 0 && socketData?.length > 0) {
      const updatedData = nseData.map((item) => {
        const symbol = item?.TradingSymbol;
        const socketItem = socketData?.find(
          (data) => data.trading_symbol === symbol
        );

        const socketPrice = socketItem?.last_price;
        const percentageChange = socketItem
          ? socketItem.percentage_change
          : item.percentage_change;
        const socketAskPrice = socketItem?.ask_price;

        const previousAskValue =
          previousAskPrices.current[symbol]?.ask_price ?? socketAskPrice;
        previousAskPrices.current[symbol] = {
          ask_price: socketItem?.ask_price,
        };

        const socketBidPrice = socketItem?.bid_price;

        const previousBidValue =
          previousBidPrices.current[symbol]?.bid_price ?? socketBidPrice;
        previousBidPrices.current[symbol] = {
          bid_price: socketItem?.bid_price,
        };

        const valueChange = socketItem
          ? socketItem.value_change
          : item.changeValue;
        const currentValue = socketItem
          ? socketItem.current_value
          : item.marketValue;
        const currentAvg = socketItem ? socketItem.price : item.avgvalue;
        const previousPrice =
          previousPrices.current[symbol]?.last_price ?? item.AveragePrice;
        previousPrices.current[symbol] = {
          last_price: socketItem ? socketItem.last_price : item.AveragePrice,
        };

        const getColor = (currentPrice, previousPrice, previousColor) => {
          const currentPriceFloat = parseFloat(currentPrice);
          const previousPriceFloat = parseFloat(previousPrice);

          let color;

          if (currentPriceFloat > previousPriceFloat) {
            color = "success";
          } else if (currentPriceFloat < previousPriceFloat) {
            color = "error";
          } else {
            color = previousColor;
          }
          return color;
        };

        const askPriceColor = getColor(
          socketAskPrice,
          previousAskValue,
          previousColors.current.askPriceColors[symbol]
        );
        const bidPriceColor = getColor(
          socketBidPrice,
          previousBidValue,
          previousColors.current.bidPriceColors[symbol]
        );

        previousColors.current.askPriceColors[symbol] = askPriceColor;
        previousColors.current.bidPriceColors[symbol] = bidPriceColor;

        return {
          ...item,
          LastPrice: socketPrice || item.AveragePrice,
          PreviousPrice: previousPrice || item.AveragePrice,
          percentage_change: percentageChange,
          changeValue: valueChange,
          marketValue: currentValue,
          Avgvalue: currentAvg || item.avgvalue,
          askPrice: socketAskPrice,
          bidPrice: socketBidPrice,
          askColor: askPriceColor,
          bidColor: bidPriceColor,
        };
      });

      SetnseData(updatedData);
      setLoader(false);
    } else {
      setLoader(false);
    }
  }, [socketData, selectedButton]);

  // API CALL TO GET PORTFOLIO DATA
  const fetchData = async (selected, page) => {
    try {
      setLoading(true);
      const data = {
        exchange: selected,
        page: page,
        pageSize: 10,
      };
      const responseData = await getPortfolio(data, token);
      if (responseData.status === "success" && responseData.portfolios) {
        const mappedPortfolios = responseData.portfolios.map((portfolio) => ({
          name2: portfolio.name,
          value: portfolio.AveragePrice,
          quantity: portfolio.Quantity,
          instrumentId: portfolio.FinancialInstrumentID,
          instrumentType: portfolio.Exchange,
          TradingSymbol: portfolio.trading_symbol,
          quantity: portfolio.Quantity,
          avgvalue: portfolio.avg_value,
          percentage_change: portfolio.percentage_change,
          marketValue: portfolio.current_value,
          changeValue: portfolio.value_change,
          lastPrice: portfolio.last_price,
        }));
        setRowCount(responseData.pagination.totalRecords);
        SetnseData(mappedPortfolios);
      } else {
        console.log("Invalid response data format");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const icon =
    portfolioValues.PNL_PERCENTAGE >= 0 ? (
      <ArrowUpwardIcon
        sx={{ height: "22px", width: "15px", paddingBottom: "2px" }}
      />
    ) : (
      <ArrowDownwardIcon
        sx={{ height: "22px", width: "15px", paddingTop: "2px" }}
      />
    );
  const percentageClassName =
    portfolioValues.PNL_PERCENTAGE < 0 ? "negative" : "";

  const selectedCurrency = storedCurrency === "INR" ? "NSE" : "NASDAQ";
  const getCurrencyvalue = portfolioValues.PNL || 0;

  // COMPONENT RENDER FUNCTION
  return (
    <Grid>
      <DashboardLayout>
        <Grid>
          <ScrollText />
        </Grid>
        <DashboardNavbar />
        {loader ? (
          <Grid
            data-testid="loader"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <CircularProgress color="info" />
          </Grid>
        ) : (
          <MDBox>
            <Grid
              container
              display="flex"
              flexDirection="row"
              justifyContent="space-around"
              flexWrap="wrap"
              alignItems="center"
              lg={12}
              md={12}
              sm={12}
              xl={12}
            >
              <Grid lg={8} md={12} xl={8} sm={12} marginTop={"10px"} xs={12}>
                <Grid
                  container
                  lg={12}
                  md={12}
                  sm={12}
                  xl={12}
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                    flexWrap: "wrap",
                    paddingRight: "15px",
                    gap: "10px",
                    paddingRight: isSmall ? "0px" : "20px",
                    paddingTop: "20px",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid lg={7} xs={12} sm={12} md={7} xl={7}>
                    <SearchBar fetchData1={() => fetchData1(null)} />
                  </Grid>

                  <Grid lg={3} xl={3} sm={12} md={4} xs={12}>
                    <Autocomplete
                      value={options.find((option) => option.id === days)}
                      onChange={(event, newValue) => {
                        setDays(newValue.id);
                      }}
                      options={options}
                      data-testid="chart-data-filter"
                      disableClearable
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{
                            "& .MuiInputBase-root": {
                              height: 45,
                              marginBottom: "5px",
                              paddingBottom: "15px",
                            },
                          }}
                        />
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                    />
                  </Grid>
                </Grid>
                <Grid lg={12} md={12} xl={12} sm={12} xs={12}>
                  <Chart
                    selected={selectedButton}
                    days={days}
                    marketContext={marketContext}
                  />
                </Grid>
              </Grid>
              <Grid
                lg={4}
                md={12}
                xl={4}
                sm={12}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                flexWrap={"wrap"}
                alignItems={"center"}
                alignSelf={"flex-end"}
                marginBottom={"20px"}
              >
                <Grid lg={12} xl={12} sm={12} xs={12} md={5}>
                  {!portfolioValues ? (
                    <Grid lg={12} md={12} sm={12} xs={12}>
                      <Skeleton
                        variant="rectangular"
                        width={"100%"}
                        height={"140px"}
                        sx={{ bgcolor: "grey.300" }}
                        animation="wave"
                      />
                    </Grid>
                  ) : (
                    <Card sx={{ height: "130px" }}>
                      <MDBox textAlign="center" marginY={2}>
                        <MDTypography
                          data-testid="current-portfolio-value"
                          sx={{ fontSize: "25px", fontWeight: 600 }}
                        >
                          Current Portfolio Value
                        </MDTypography>

                        <MDBox
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "15px",
                            justifyContent: "center",
                          }}
                        >
                          <MDTypography
                            sx={{
                              fontWeight: 420,
                              fontSize: "25px",
                              color: getCurrencyvalue >= 0 ? "none" : "#FF6961",
                            }}
                          >
                            {/* {storedCurrency === "INR" ? "₹" : "$"} */}
                            {formatAndMultiplyValueAssetItem(
                              Math.abs(getCurrencyvalue),
                              selectedCurrency
                            )}
                          </MDTypography>
                          <Card
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              height: "30px",
                              width: "80px",
                              justifyContent: "center",
                              backgroundColor: "#e6e6e6",
                              borderRadius: "20px",
                            }}
                          >
                            <MDTypography
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                fontSize: "15px",
                                gap: "1px",
                                alignItems: "center",
                                fontWeight: 500,
                              }}
                              color={
                                percentageClassName === "negative"
                                  ? "error"
                                  : "success"
                              }
                            >
                              {icon}
                              <span>
                                {portfolioValues.percentage === "NaN"
                                  ? "0.00"
                                  : Math.abs(
                                      portfolioValues.PNL_PERCENTAGE || 0
                                    ).toFixed(2)}
                                %
                              </span>
                            </MDTypography>
                          </Card>
                        </MDBox>
                      </MDBox>
                    </Card>
                  )}
                </Grid>
                {enabledmarkets?.length >= 1 && (
                  <Grid mt={2} lg={12} xl={12} sm={12} xs={12} md={6.5}>
                    <PortfolioCard
                      marketContext={marketContext}
                      exchange={selectedButton}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            {enabledmarkets?.length > 1 ? (
              <Card
                sx={{
                  borderRadius: "10px",
                  marginBottom: "15px",
                  position: "relative",
                  zIndex: 1, // Ensure it stays above other components within its contex
                }}
              >
                <Tabs
                  value={enabledMarketContext.findIndex(
                    (item) => item.id === selectedButton
                  )}
                  // onChange={(event, newValue) => handleButtonClick(newValue)}
                  variant="scrollable"
                  scrollButtons="auto"
                  allowScrollButtonsMobile
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    width: "100%",
                    cursor: "pointer",
                    [`& .${tabsClasses.scrollButtons}`]: {
                      "&.Mui-disabled": { opacity: 0.5 },
                      color: darkMode ? white.main : black.main,
                    },
                  }}
                >
                  {enabledMarketContext.map((item) => (
                    <MDBox
                      key={item.id}
                      sx={{
                        display: "flex",
                        flex: sorted ? 2 : "none",
                        // width: { xs: 200, sm: 300, md: 500, xl: "none", lg: "none" },
                        width: "100%", // Set the container width to 100%
                        flexDirection: "row",
                        justifyContent: "center",
                        gap: "10px",
                        alignItems: "center",
                        textAlign: "center",
                        borderRadius: "6px",
                        marginX: "2px",
                        marginY: "4px",
                        paddingX: "15px",
                        cursor: "pointer",
                        backgroundImage:
                          item.id === selectedButton
                            ? "linear-gradient(195deg, #49a3f1, #1A73E8)"
                            : getGradientOnTheme(),
                      }}
                      data-testid={`select-${item.id}`}
                      onClick={() => handleButtonClick(item.id)}
                    >
                      <MDTypography
                        sx={{
                          fontSize: "14px",
                          paddingX: "3px",
                          paddingY: "5px",
                          color: item.id === selectedButton ? "#FFFFFF" : null,
                        }}
                        fontWeight="bold"
                      >
                        {item.label}
                      </MDTypography>
                      <MDTypography
                        sx={{
                          fontSize: "13px",
                          paddingX: "3px",
                          paddingY: "5px",
                          fontWeight: item.id === selectedButton ? 500 : 520,
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          color:
                            item.id === selectedButton
                              ? "#FFFFFF"
                              : item.totalValue < 0
                              ? "#e53935"
                              : "#43a047",
                        }}
                      >
                        {`${formatAndMultiplyValueAssetItem(
                          Math.abs(item.totalValue),
                          item.label
                        )}(${Math.abs(item.percentage)}%)`}
                      </MDTypography>
                      {/* )} */}
                    </MDBox>
                  ))}
                </Tabs>
              </Card>
            ) : null}

            <CardItemsNasdaq
              title={selectedButton}
              list={nseData}
              loading={loading}
              data-testid="table-component"
              rowCount={rowCount}
              page={page}
              setPage={setpage}
            />
          </MDBox>
        )}
      </DashboardLayout>
    </Grid>
  );
}
export default Dashboard;
